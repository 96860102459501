.wrap.main {
  position: relative;
  min-width: 1260px;
  /* background: url("assets/img/main/bg_main.png") center top no-repeat; */
  background-size: 1920px auto;
}

.header .gnb a {
  color: #fff !important;
}
.header .logo {
  background-image: url("assets/img/common/logo_header_main.png") !important;
}
.header .language .btn-language {
  background-image: url("assets/img/common/ico_language_main.png") !important;
}

.top {
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(to bottom, rgba(0, 12, 41, 1), rgba(1, 9, 33, 1));
}
.top .inner {
  position: relative;
  min-width: 1264px;
  height: 1080px;
  margin: 0 auto;
  background: url(assets/img/main/bg_main_visual.png) center top no-repeat;
  background-size: 2580px auto;
}
.top .inner h1 {
  position: absolute;
  left: 0;
  bottom: 124px;
  text-align: center;
  color: #fff;
  width: 100%;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: -0.6px;
}

.ai-patents .inner {
  width: 1264px;
  margin: 0 auto;
  padding: 180px 0 200px 0;
}
.ai-patents h2 {
  position: relative;
  margin-bottom: 124px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: -0.48px;
  color: var(--color-font-secondary);
}
.ai-patents h2:before {
  position: absolute;
  top: 0;
  left: 838px;
  content: "";
  width: 310px;
  height: 310px;
  background: url("assets/img/main/bg_ai_patents.png") no-repeat;
  background-size: 310px auto;
}
.ai-patents h3 {
  margin-bottom: 24px;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.33;
  letter-spacing: -0.36px;
  color: var(--color-font-secondary);
}
.ai-patents p {
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-primary);
}
.ai-patents .item {
  position: relative;
  box-sizing: border-box;
}
.ai-patents .item1 {
  height: 454px;
  margin-bottom: 120px;
  padding: 168px 0 0 724px;
}
.ai-patents .item2 {
  height: 552px;
  margin-bottom: 77px;
  padding: 121px 756px 0 0;
}
.ai-patents .item3 {
  height: 540px;
  margin-bottom: 139px;
  padding: 158px 76px 0 648px;
}
.ai-patents .item4 {
  height: 454px;
  padding: 158px 723px 0 0;
}
.ai-patents .item .bg {
  position: absolute;
  top: 0;
}
.ai-patents .item1 .bg {
  left: 0;
  width: 615px;
  height: 454px;
  background: url("assets/img/main/bg_item1.png") no-repeat;
  background-size: 100% auto;
}
.ai-patents .item2 .bg {
  right: 0;
  width: 552px;
  height: 552px;
  background: url("assets/img/main/bg_item2.png") no-repeat;
  background-size: 100% auto;
}
.ai-patents .item3 .bg {
  left: 0;
  width: 566px;
  height: 540px;
  background: url("assets/img/main/bg_item3.png") no-repeat;
  background-size: 100% auto;
}
.ai-patents .item4 .bg {
  right: 0;
  width: 615px;
  height: 454px;
  background: url("assets/img/main/bg_item4.png") no-repeat;
  background-size: 100% auto;
}

.history .inner {
  position: relative;
  width: 1264px;
  margin: 0 auto;
  padding: 180px 0 50px 0;
  box-sizing: border-box;
}
.history h2 {
  margin-bottom: 24px;
  line-height: 72px;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  color: var(--color-font-secondary);
}
.history p {
  width: 1048px;
  margin: 0 auto 52px auto;
  text-align: center;
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-secondary);
}
.history .list {
  position: relative;
  padding-left: 540px;
  box-sizing: border-box;
}
.history .bg {
  position: absolute;
  left: -328px;
  top: 74px;
  width: 768px;
  height: 768px;
  display: block;
  background: url("assets/img/main/bg_history.png") no-repeat;
  background-size: 726px auto;
}
.history dl {
  position: relative;
  padding: 24px 0 12px 168px;
  border-bottom: 1px solid var(--color-border-content);
}
.history dt {
  position: absolute;
  left: 0;
  top: 24px;
  font-size: 32px;
  font-weight: 800;
  line-height: 0.94;
  color: var(--color-font-secondary);
}
.history dd {
  position: relative;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.5;
  padding-left: 16px;
  color: var(--color-font-primary);
}
.history dd:before {
  position: absolute;
  left: 0;
  top: 8px;
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  background: var(--color-font-primary);
  border-radius: 4px;
}
.history dl.last {
  border-bottom: 0;
  padding-bottom: 0;
}

/*----------------------------------------
            mobile
-------------------------------------------*/
@media all and (max-width: 1023px) {
  .wrap.main {
    min-width: 100%;
    /* background: url("assets/img/main/bg_main_m.png") center top no-repeat; */
    background-size: 100% auto;
  }

  .header .logo {
    background-image: url("assets/img/common/logo_header_main_m.png");
  }
  .header .btn-menu-open {
    background-image: url("assets/img/common/btn_menu_open_main.png") !important;
  }

  .top {
    width: 100%;
    margin: 0 auto;
  }
  .top .inner {
    width: 100%;
    min-width: 100%;
    height: 200vw;
    background: url(assets/img/main/bg_main_visual_m.png) center top no-repeat;
    background-size: 100% auto;
  }
  .top .inner h1 {
    padding: 0;
    width: 256px;
    left: 50%;
    bottom: 14.44vw;
    margin-left: -129px;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.44;
    letter-spacing: -0.32px;
  }

  .ai-patents .inner {
    width: 100%;
    padding: 80px 20px 0 20px;
    box-sizing: border-box;
  }
  .ai-patents h2 {
    position: relative;
    margin-bottom: 220px;
    font-size: 28px;
    font-weight: 800;
    line-height: 1.36;
    letter-spacing: -0.28px;
    color: var(--color-font-secondary);
  }
  .ai-patents h2:before {
    left: initial;
    top: 72px;
    right: 0;
    content: "";
    width: 210px;
    height: 210px;
    background: url("assets/img/main/bg_ai_patents_m.png") no-repeat;
    background-size: 210px auto;
    z-index: -1;
  }
  .ai-patents h3 {
    margin-bottom: 16px;
    font-size: 24px;
    letter-spacing: -0.24px;
  }
  .ai-patents p {
    font-size: 16px;
    line-height: 1.63;
    color: var(--color-font-primary);
  }
  .ai-patents .item1,
  .ai-patents .item2,
  .ai-patents .item3 {
    height: auto;
    margin-bottom: 80px;
    padding: 0;
  }
  .ai-patents .item4 {
    height: auto;
    padding: 0;
  }

  .ai-patents .item .bg {
    position: relative;
    top: initial;
    margin-bottom: 48px;
  }
  .ai-patents .item1 .bg {
    left: initial;
    width: 100%;
    height: 65.55vw;
    background: url("assets/img/main/bg_item1_m.png") no-repeat;
    background-size: auto 65.55vw;
  }
  .ai-patents .item2 .bg {
    right: initial;
    width: 100%;
    height: 80.55vw;
    background: url("assets/img/main/bg_item2_m.png") no-repeat;
    background-size: auto 80.55vw;
  }
  .ai-patents .item3 .bg {
    left: initial;
    width: 100%;
    height: 80.55vw;
    background: url("assets/img/main/bg_item3_m.png") no-repeat;
    background-size: auto 80.55vw;
  }
  .ai-patents .item4 .bg {
    right: initial;
    width: 100%;
    height: 65.55vw;
    background: url("assets/img/main/bg_item4_m.png") no-repeat;
    background-size: auto 65.55vw;
  }

  .history .inner {
    width: 100%;
    margin: 0 auto;
    padding: 100px 20px 0 20px;
    box-sizing: border-box;
  }
  .history h2 {
    text-align: left;
    margin-bottom: 20px;
    line-height: 1.44;
    letter-spacing: -0.32px;
    font-size: 32px;
  }
  .history p {
    width: 100%;
    margin: 0 auto 60px auto;
    text-align: left;
  }
  .history .list {
    position: relative;
    padding-left: 0;
    box-sizing: border-box;
  }
  .history .bg {
    position: relative;
    left: initial;
    top: initial;
    width: calc(100% + 40px);
    margin-left: -20px;
    height: 88.88vw;
    background: url("assets/img/main/bg_history_m.png") no-repeat;
    background-size: auto 88.88vw;
  }
  .history dl {
    position: relative;
    padding: 30px 0 32px 0;
    border-bottom: 1px solid var(--color-border-content);
  }
  .history dt {
    position: relative;
    left: initial;
    top: initial;
    margin-bottom: 16px;
    font-size: 28px;
    font-weight: 800;
    line-height: 0.86;
  }
  .history dd {
    position: relative;
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 1.47;
    padding-left: 16px;
    color: var(--color-font-primary);
  }
  .history dl:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .header.layer-open .btn-language {
    background-image: url("assets/img/common/ico_language.png") !important;
  }
}
