/*-----------------------------------------
            common
-------------------------------------------*/

.page-title {
  width: 1264px;
  padding: 260px 0 80px 0;
  margin: 0 auto;
}
.page-title h2 {
  margin-bottom: 24px;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 72px;
  font-weight: 800;
  line-height: 1.11;
  letter-spacing: -0.72px;
  color: var(--color-font-secondary);
}
.page-title p {
  opacity: 0.9;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: -0.18px;
  color: #191919;
}

/*-----------------------------------------
            pc
-------------------------------------------*/

.ai-with-blockchain {
  padding-bottom: 150px;
}
.ai-with-blockchain .img {
  width: 100%;
  height: 520px;
  max-width: 1680px;
  margin: 0 auto 38px auto;
  background: url("assets/img/ai_with_blockchain/bg_img.png") no-repeat;
  background-size: 1680px auto;
}
.ai-with-blockchain .img p {
  width: 1264px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 376px 0 0 432px;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5;
  color: var(--color-white);
}
.ai-with-blockchain .txt {
  width: 1264px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 42px 0 0 432px;
}
.ai-with-blockchain .txt dt {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.4;
  color: var(--color-font-secondary);
}
.ai-with-blockchain .txt dd {
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-primary);
}
.ai-with-blockchain .line p {
  line-height: 1.63;
  font-weight: bold;
  color: var(--color-font-secondary);
  font-size: 16px;
  padding-top: 48px;
  border-top: 1px solid var(--color-border-content2);
}

.we-are {
  padding-bottom: 50px;
}
.we-are .img {
  width: 100%;
  height: 520px;
  max-width: 1680px;
  margin: 0 auto;
  background: url("assets/img/we_are/bg_img.png") no-repeat;
  background-size: 1680px auto;
}
.we-are .txt {
  position: relative;
  width: 1264px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 120px;
}
.we-are .txt h3 {
  font-size: 40px;
  margin-bottom: 24px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.5;
  color: var(--color-font-secondary);
}
.we-are .txt p {
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-primary);
}
.we-are .txt p:first-child {
  margin-top: 0;
}
.we-are .txt1 {
  padding-right: 432px;
  margin-bottom: 120px;
}
.we-are .txt1 .bg {
  position: absolute;
  left: 900px;
  top: 294px;
  width: 597px;
  height: 597px;
  background: url("assets/img/we_are/bg_txt1.png") no-repeat;
  background-size: 597px auto;
  z-index: -1;
}
.we-are .txt2 {
  padding-left: 648px;
  padding-top: 113px;
}
.we-are .txt2:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 540px;
  height: 540px;
  content: "";
  display: block;
  background: url("assets/img/we_are/bg_txt2.png") no-repeat;
  background-size: 540px auto;
  z-index: -1;
}
.we-are .txt2 p {
  width: 616px;
}

.services-and-expertise {
  padding-bottom: 160px;
}
.services-and-expertise .img {
  width: 100%;
  height: 520px;
  max-width: 1680px;
  margin: 0 auto;
  background: url("assets/img/services_and_expertise/bg_img.png") no-repeat;
  background-size: 1680px auto;
}
.services-and-expertise .txt-list {
  position: relative;
  width: 1264px;
  margin: 0 auto;
  padding-top: 20px;
  overflow: hidden;
}
.services-and-expertise .txt-list .item {
  float: left;
  width: 582px;
  margin-top: 100px;
}
.services-and-expertise .txt-list .item dt {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 800;
  line-height: 2.14;
  color: var(--color-font-secondary);
}
.services-and-expertise .txt-list .item dd {
  position: relative;
  margin-bottom: 12px;
  padding-left: 16px;
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-primary);
}
.services-and-expertise .txt-list .item dd:before {
  position: absolute;
  left: 0;
  top: 10px;
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #b1b1b1;
}
.services-and-expertise .txt-list .item dd:last-child {
  margin-bottom: 0;
}
.services-and-expertise .txt-list .item1 {
  margin-right: 100px;
}
.services-and-expertise .txt-list .item3 {
  clear: both;
  margin-right: 100px;
}
.services-and-expertise .txt-list .item5 {
  clear: both;
  margin-bottom: 80px;
}
.services-and-expertise .txt-list .txt {
  clear: both;
  width: 582px;
  padding-top: 80px;
  border-top: 1px solid var(--color-border-content2);
}
.services-and-expertise .txt-list .txt p {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  color: var(--color-font-secondary);
}
.services-and-expertise .txt-list .txt p:last-child {
  margin-bottom: 0;
}
.services-and-expertise .txt-list .bg {
  position: absolute;
  left: 682px;
  top: 789px;
  width: 582px;
  height: 550px;
  background: url("assets/img/services_and_expertise/bg_item.png") no-repeat;
  background-size: 582px auto;
}

.contact {
  padding-bottom: 50px;
}
.contact .img {
  width: 100%;
  height: 320px;
  max-width: 1680px;
  margin: 0 auto 100px auto;
  background: url("assets/img/contact/bg_img.png") no-repeat;
  background-size: 1680px auto;
}
.contact .list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 80px 62px;
  width: 1260px;
  margin: 0 auto;
}
.contact .item dt {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  color: #999;
}
.contact .item dd {
  padding-bottom: 40px;
  border-bottom: 6px solid #ffe033;
}
.contact .item dd a {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.18;
  color: var(--color-font-secondary);
}
.contact .txt {
  width: 1260px;
  margin: 0 auto;
  padding-top: 80px;
}
.contact .txt p {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  color: var(--color-font-secondary);
}
.contact .txt p:last-child {
  margin-bottom: 0;
}

.ai-with-patents .page-title {
  position: relative;
}
.ai-with-patents .img {
  position: absolute;
  right: 32px;
  top: 348px;
  width: 368px;
  height: 367px;
  background: url("assets/img/ai_with_patents/bg_title.png") no-repeat;
  background-size: 367px auto;
}
.ai-with-patents .txt-top {
  width: 1264px;
  margin: 0 auto 104px auto;
  background: url("assets/img/ai_with_patents/bg_top.png") right 234px no-repeat;
  background-size: 616px auto;
}
.ai-with-patents .txt-top .inner {
  width: 540px;
}
.ai-with-patents .txt-top h3 {
  padding-bottom: 32px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: -0.24px;
  color: var(--color-font-secondary);
  border-bottom: 4px solid var(--color-font-secondary);
}
.ai-with-patents .txt-top dl {
  margin-top: 42px;
}
.ai-with-patents .txt-top dt {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.4;
  color: var(--color-font-secondary);
}
.ai-with-patents .txt-top dd {
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-primary);
}

.ai-with-patents .txt-bottom {
  width: 1264px;
  margin: 0 auto;
  padding: 88px 0 120px 0;
  border-top: 1px solid var(--color-border-content2);
}
.ai-with-patents .txt-bottom h3 {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 1.3;
  color: var(--color-font-secondary);
}
.ai-with-patents .txt-bottom .boxs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  align-items: stretch;
  margin-bottom: 32px;
}
.ai-with-patents .txt-bottom .boxs dl {
  padding: 32px 24px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #f5f7f8;
}
.ai-with-patents .txt-bottom .boxs dt {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  color: var(--color-font-secondary);
}
.ai-with-patents .txt-bottom .boxs dd {
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-secondary);
}
.ai-with-patents .txt-bottom p {
  text-align: center;
  font-size: 14px;
  line-height: 1.86;
  color: var(--color-font-primary);
}

.ai-with-patents .patents-spot {
  width: 100%;
  background: #2bc48c;
}
.ai-with-patents .patents-spot .inner {
  position: relative;
  width: 1264px;
  height: 840px;
  margin: 0 auto;
  padding: 150px 0 150px 648px;
  box-sizing: border-box;
}
.ai-with-patents .patents-spot h3 {
  position: absolute;
  left: 0;
  top: 150px;
  display: block;
  width: 540px;
  height: 540px;
  padding: 207px 80px 0 80px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
  line-height: 1.31;
  font-weight: 800;
  font-size: 32px;
  box-sizing: border-box;
  background: url("assets/img/ai_with_patents/bg_spot.png") center no-repeat;
  background-size: 558px auto;
}
.ai-with-patents .patents-spot h3:before {
  position: absolute;
  left: 0;
  top: 380px;
  content: "";
  display: block;
  width: 160px;
  height: 160px;
  background: url("assets/img/ai_with_patents/bg_spot2.png") no-repeat;
  background-size: 160px auto;
}
.ai-with-patents .patents-spot dl {
  position: relative;
  padding: 30px 0 30px 126px;
  min-height: 132px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.ai-with-patents .patents-spot dl:before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -66px;
  display: block;
  content: "";
  width: 96px;
  height: 132px;
  background-repeat: no-repeat;
  background-size: 96px auto;
}
.ai-with-patents .patents-spot dl:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.ai-with-patents .patents-spot dl:first-child {
  padding-top: 32px;
}
.ai-with-patents .patents-spot dt {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  color: #fff;
}
.ai-with-patents .patents-spot dt .no {
  display: block;
}
.ai-with-patents .patents-spot dd {
  opacity: 0.84;
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-white);
}
.ai-with-patents .patents-spot .patent1:before {
  background-image: url("assets/img/ai_with_patents/img_patents4.png");
}
.ai-with-patents .patents-spot .patent2:before {
  background-image: url("assets/img/ai_with_patents/img_patents5.png");
}
.ai-with-patents .patents-spot .patent3:before {
  margin-top: -51px;
  background-image: url("assets/img/ai_with_patents/img_patents6.png");
}

.ai-with-patents .patents {
  position: relative;
  width: 1264px;
  margin: 100px auto 0 auto;
  padding: 100px 540px 0 0;
  box-sizing: border-box;
  border-top: 10px solid var(--color-border-content3);
}
.ai-with-patents .patents:first-child {
  border: 0;
  padding-top: 0;
}
.ai-with-patents .patents .title {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-font-secondary);
}
.ai-with-patents .patents .title .no {
  display: block;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.75;
  color: var(--color-font-green);
}
.ai-with-patents .patents .title h3 {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.31;
  color: var(--color-font-secondary);
}
.ai-with-patents .patents .txt {
  padding-top: 32px;
  padding-bottom: 4px;
}
.ai-with-patents .patents .txt p {
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-secondary);
}
.ai-with-patents .patents .txt.line {
  margin-top: 32px;
  border-top: 1px solid var(--color-border-content3);
}
.ai-with-patents .patents dl {
  margin-top: 28px;
}
.ai-with-patents .patents dl dt {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.56;
  color: var(--color-font-secondary);
}
.ai-with-patents .patents dl dd {
  font-size: 16px;
  line-height: 1.63;
  color: var(--color-font-primary);
}

.ai-with-patents .patents1 {
  background: url("assets/img/ai_with_patents/img_patents1.png") right 44px
    no-repeat;
  background-size: 400px auto;
}
.ai-with-patents .patents2 {
  background: url("assets/img/ai_with_patents/img_patents2.png") right 44px
    no-repeat;
  background-size: 400px auto;
}
.ai-with-patents .patents3 {
  background: url("assets/img/ai_with_patents/img_patents3.png") right 44px
    no-repeat;
  background-size: 400px auto;
}

/*-----------------------------------------
            mobile
-------------------------------------------*/
@media all and (max-width: 1023px) {
  .page-title {
    width: auto;
    padding: 132px 20px 30px 20px;
    margin: 0 auto;
  }
  .page-title .title {
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 1.33;
    letter-spacing: -0.36px;
  }
  .page-title p {
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: -0.16px;
  }

  .ai-with-blockchain {
    padding-bottom: 20px;
  }

  .ai-with-blockchain .img {
    position: relative;
    width: 100%;
    height: 77.77vw;
    max-width: auto;
    margin: 0 auto 20px auto;
    background: url("assets/img/ai_with_blockchain/bg_img_m.png") no-repeat;
    background-size: 100% auto;
  }
  .ai-with-blockchain .img p {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-size: 20px;
  }
  .ai-with-blockchain .txt {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 20px 0 20px;
  }
  .ai-with-blockchain .txt dt {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.56;
  }
  .ai-with-blockchain .txt dd {
    font-size: 15px;
    line-height: 1.6;
  }
  .ai-with-blockchain .line p {
    line-height: 1.6;
    font-size: 15px;
    padding-top: 40px;
  }

  .we-are {
    padding-bottom: 20px;
  }
  .we-are .page-title p br {
    display: none;
  }
  .we-are .img {
    width: 100%;
    height: 77.77vw;
    max-width: auto;
    margin: 0 auto 80px auto;
    background: url("assets/img/we_are/bg_img_m.png") no-repeat;
    background-size: 100% auto;
  }
  .we-are .txt {
    width: 100%;
    padding: 0 20px;
  }
  .we-are .txt h3 {
    font-size: 26px;
    margin-bottom: 22px;
    line-height: 1.23;
  }
  .we-are .txt p {
    margin-top: 20px;
    font-size: 15px;
    line-height: 1.6;
  }
  .we-are .txt1 {
    padding-right: 0;
    margin-bottom: 80px;
  }
  .we-are .txt1 .bg {
    position: relative;
    left: initial;
    top: initial;
    width: 80.27vw;
    height: 80.27vw;
    margin: 0 auto -90px auto;
    background: url("assets/img/we_are/bg_txt1_m.png") no-repeat;
    background-size: 100% auto;
  }
  .we-are .txt2 {
    padding: 0 20px;
  }
  .we-are .txt2:before {
    position: relative;
    left: initial;
    top: initial;
    width: 75.55vw;
    height: 75.55vw;
    margin: 0 auto 60px auto;
    background: url("assets/img/we_are/bg_txt2_m.png") no-repeat;
    background-size: 100% auto;
  }
  .we-are .txt2 p {
    width: auto;
  }

  .services-and-expertise {
    padding-bottom: 20px;
  }
  .services-and-expertise .page-title p br {
    display: none;
  }
  .services-and-expertise .img {
    width: 100%;
    height: 77.77vw;
    max-width: auto;
    margin: 0 auto;
    background: url("assets/img/services_and_expertise/bg_img_m.png") no-repeat;
    background-size: 100% auto;
  }
  .services-and-expertise .txt-list {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .services-and-expertise .txt-list .item {
    float: none;
    width: 100%;
    margin-top: 60px;
  }
  .services-and-expertise .txt-list .item dt {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.6;
  }
  .services-and-expertise .txt-list .item dd {
    position: relative;
    margin-bottom: 12px;
    padding-left: 16px;
    font-size: 15px;
    line-height: 1.47;
  }
  .services-and-expertise .txt-list .item dd:before {
    position: absolute;
    left: 0;
    top: 8px;
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background: #b1b1b1;
  }
  .services-and-expertise .txt-list .item dd:last-child {
    margin-bottom: 0;
  }
  .services-and-expertise .txt-list .item1 {
    margin-right: 0;
  }
  .services-and-expertise .txt-list .item3 {
    margin-right: 0;
    margin-bottom: 90px;
  }
  .services-and-expertise .txt-list .item5 {
    margin-bottom: 40px;
  }
  .services-and-expertise .txt-list .txt {
    width: 100%;
    padding-top: 40px;
  }
  .services-and-expertise .txt-list .txt p {
    font-size: 15px;
    line-height: 1.6;
  }
  .services-and-expertise .txt-list .bg {
    position: relative;
    left: initial;
    top: initial;
    margin-bottom: 40px;
    width: 88.88vw;
    height: 88.88vw;
    margin: 0 auto;
    background: url("assets/img/services_and_expertise/bg_item_m.png") no-repeat;
    background-size: 100% auto;
  }

  .contact {
    padding-bottom: 20px;
  }
  .contact .img {
    width: 100%;
    height: 61.11vw;
    max-width: auto;
    margin: 0 auto 60px auto;
    background: url("assets/img/services_and_expertise/bg_img_m.png") no-repeat;
    background-size: 100% auto;
  }
  .contact .list {
    display: block;
    grid-template-columns: none;
    gap: initial;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
  .contact .item {
    margin-bottom: 72px;
  }
  .contact .item dt {
    margin-bottom: 16px;
  }
  .contact .item dd {
    padding-bottom: 32px;
  }
  .contact .txt {
    width: 100%;
    padding: 8px 20px 0 20px;
    box-sizing: border-box;
  }
  .contact .txt p {
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 1.6;
  }

  .ai-with-patents {
    padding-bottom: 20px;
  }
  .ai-with-patents .page-title {
    position: relative;
  }
  .ai-with-patents .img {
    clear: both;
    float: right;
    position: relative;
    right: initial;
    top: initial;
    width: 190px;
    height: 190px;
    background: url("assets/img/ai_with_patents/bg_title_m.png") no-repeat;
    background-size: 190px auto;
  }
  .ai-with-patents .txt-top {
    clear: both;
    width: 100%;
    box-sizing: border-box;
    padding: 40px 20px 88.88vw 20px;
    margin: 0;
    background: url("assets/img/ai_with_patents/bg_top_m.png") center bottom
      no-repeat;
    background-size: 88.88vw auto;
  }
  .ai-with-patents .txt-top .inner {
    width: 100%;
  }
  .ai-with-patents .txt-top h3 {
    padding-bottom: 32px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: 0;
  }
  .ai-with-patents .txt-top dl {
    margin-top: 40px;
  }
  .ai-with-patents .txt-top dl:last-child {
    margin-bottom: 40px;
  }
  .ai-with-patents .txt-top dt {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.56;
  }
  .ai-with-patents .txt-top dd {
    font-size: 15px;
    line-height: 1.6;
  }

  .ai-with-patents .txt-bottom {
    width: 100%;
    padding: 60px 20px 72px 20px;
    border: 0;
    box-sizing: border-box;
  }
  .ai-with-patents .txt-bottom h3 {
    margin-bottom: 24px;
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
  }
  .ai-with-patents .txt-bottom .boxs {
    display: block;
    grid-template-columns: none;
    gap: initial;
    align-items: stretch;
    margin-bottom: 24px;
  }
  .ai-with-patents .txt-bottom .boxs dl {
    padding: 20px;
    border-radius: 6px;
    margin-top: 16px;
  }
  .ai-with-patents .txt-bottom .boxs dl:first-child {
    margin-top: 0;
  }
  .ai-with-patents .txt-bottom .boxs dt {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 1.73;
  }
  .ai-with-patents .txt-bottom .boxs dd {
    font-size: 14px;
    line-height: 1.57;
  }
  .ai-with-patents .txt-bottom p {
    text-align: left;
    font-size: 14px;
    line-height: 1.57;
  }

  .ai-with-patents .patents-spot {
    width: 100%;
    background: #2bc48c;
  }
  .ai-with-patents .patents-spot .inner {
    width: 100%;
    height: auto;
    padding: 80px 20px;
    box-sizing: border-box;
  }
  .ai-with-patents .patents-spot h3 {
    position: relative;
    left: initial;
    top: initial;
    display: block;
    width: 311px;
    height: 311px;
    margin: 0 auto 32px auto;
    padding: 111px 20px 0 20px;
    line-height: 1.36;
    font-size: 22px;
    background: url("assets/img/ai_with_patents/bg_spot_m.png") no-repeat;
    background-size: 311px auto;
  }
  .ai-with-patents .patents-spot h3:before {
    position: absolute;
    left: 228px;
    top: 219px;
    width: 92px;
    height: 92px;
    background: url("assets/img/ai_with_patents/bg_spot2_m.png") no-repeat;
    background-size: 92px auto;
  }
  .ai-with-patents .patents-spot dl {
    padding: 30px 0;
    min-height: 96px;
  }
  .ai-with-patents .patents-spot dl:before {
    background-size: 70px auto;
    top: 30px;
    margin: 0;
  }
  .ai-with-patents .patents-spot dl:first-child {
    padding-top: 30px;
  }
  .ai-with-patents .patents-spot dt {
    padding: 0 0 0 86px;
    min-height: 96px;
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 1.5;
  }
  .ai-with-patents .patents-spot dt .no {
    margin-bottom: 10px;
  }
  .ai-with-patents .patents-spot dd {
    font-size: 15px;
    line-height: 1.6;
  }
  .ai-with-patents .patents-spot .patent1:before {
    background-image: url("assets/img/ai_with_patents/img_patents4_m.png");
  }
  .ai-with-patents .patents-spot .patent2:before {
    background-image: url("assets/img/ai_with_patents/img_patents5_m.png");
  }
  .ai-with-patents .patents-spot .patent3:before {
    top: 30px;
    margin: 0;
    background-image: url("assets/img/ai_with_patents/img_patents6_m.png");
  }

  .ai-with-patents .patents-list {
    padding: 0 20px;
  }
  .ai-with-patents .patents {
    position: relative;
    width: 100%;
    margin: 72px auto 0 auto;
    padding: 72px 0 0 0;
    border-top: 10px solid var(--color-border-content3);
    background: none;
  }
  .ai-with-patents .patents:first-child {
    border: 0;
    padding-top: 0;
    margin-top: 80px;
  }
  .ai-with-patents .patents .title {
    padding-bottom: 28px;
  }
  .ai-with-patents .patents .title .no {
    display: block;
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 1.87;
  }
  .ai-with-patents .patents .title h3 {
    font-size: 22px;
    line-height: 1.36;
  }
  .ai-with-patents .patents .txt {
    padding-top: 30px;
    padding-bottom: 2px;
  }
  .ai-with-patents .patents .txt p {
    font-size: 15px;
    line-height: 1.6;
  }
  .ai-with-patents .patents .txt.line {
    margin-top: 30px;
  }
  .ai-with-patents .patents .txt.line-m-none {
    border: 0;
    margin: 0;
    padding-top: 0;
  }
  .ai-with-patents .patents dl {
    margin-top: 28px;
  }
  .ai-with-patents .patents dl dt {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 1.75;
  }
  .ai-with-patents .patents dl dd {
    font-size: 15px;
    line-height: 1.6;
  }
  .ai-with-patents .patents .bg {
    width: 100%;
    height: 88.88vw;
    margin: 60px auto;
  }

  .ai-with-patents .patents1 .bg {
    background: url("assets/img/ai_with_patents/img_patents1_m.png") center
      no-repeat;
    background-size: 88.88vw auto;
  }
  .ai-with-patents .patents2 .bg {
    background: url("assets/img/ai_with_patents/img_patents2.png") center
      no-repeat;
    background-size: 88.88vw auto;
  }
  .ai-with-patents .patents3 .bg {
    background: url("assets/img/ai_with_patents/img_patents3.png") center
      no-repeat;
    background-size: 88.88vw auto;
  }
}
