/*-----------------------------------------
    default
-------------------------------------------*/

:root {
  --color-background: #fff;
  --color-store-title: #f5f7f8;
}

/*-----------------------------------------
    layout
-------------------------------------------*/

/* header */
.header {
  border-bottom: 1px solid var(--color-border-gray3);
}
.header .gnb a {
  color: var(--color-font-primary);
}
.header .logo {
  /* background-image: url("assets/img/common/logo_header_store.png"); */
}
.header .current {
  color: var(--color-font-primary);
}
.header .avatar {
  /* background-image: url("assets/img/commonassets/img_avatar_store.png"); */
}
.header .wallet-id {
  background: #f5f7f8;
}
.header .wallet-id .id {
  color: var(--color-font-primary);
}
.header .btn {
  color: var(--color-font-dark1);
  border: solid 1px var(--color-border-gray3);
}
.header .btn-search {
  /* background-image: url("assets/img/common/ico_search_store.png"); */
}
.header .btn-language {
  /* background-image: url("assets/img/common/ico_language_store.png"); */
}

/* footer */
.footer {
  border-color: var(--color-border-gray2);
}
.footer .logo {
  /* background-image: url("assets/img/common/logo_footer_store.png"); */
}
.footer .sns .twitter {
  /* background-image: url("assets/img/common/twitter_store@2x.png"); */
}
.footer .sns .facebook {
  /* background-image: url("assets/img/common/facebook_store@2x.png"); */
}
.footer .sns .youtube {
  /* background-image: url("assets/img/common/youtube_store@2x.png"); */
}
.footer .sns .instagram {
  /* background-image: url("assets/img/common/instagram_store@2x.png"); */
}
.footer .links a strong,
.footer .links a {
  color: var(--color-font-primary);
}
.footer .copyright {
  color: var(--color-font-gray6);
}

/* common */
.layer-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 999;
  background: none;
}
.layer-popup:before {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  background: var(--color-black);
  opacity: 0.8;
  z-index: 1;
}
.layer-popup > .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 60px;
  max-height: 100%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  box-sizing: border-box;
  z-index: 2;
  background: var(--color-white);
}
.layer-popup > .inner:after {
  clear: both;
  display: block;
  content: "";
}
.layer-popup .title {
  overflow: hidden;
  padding-bottom: 24px;
  background: var(--color-white);
}
.layer-popup .title h3 {
  margin-top: 19px;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.28px;
  color: var(--color-white);
}
.layer-popup .btn-layer-close {
  position: absolute;
  right: 44px;
  top: 38px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  font-size: 0;
  text-indent: -99px;
  border: 0;
  overflow: hidden;
  /* background: url("assets/img/join/btn_agree_layer_close.png") no-repeat; */
  background-size: 32px 32px;
}

/* input */
.store-order .form-grid .input-txt {
  width: 100%;
  height: 46px;
  line-height: 46px;
  text-indent: 20px;
  color: var(--color-font-primary);
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  background-color: var(--color-background-input-store);
  border: 1px solid #f0f0f0;
}
.store-order .form-grid .input-txt + .msg {
  display: none;
  padding-top: 8px;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: -0.12px;
  color: var(--color-success);
}
.store-order .form-grid .input-txt:placeholder {
  color: var(---color-font-gray2);
}
.store-order .form-grid .input-txt:hover {
  border-color: var(--color-primary);
}
.store-order .form-grid .input-txt:focus {
  border-color: var(--color-primary);
  background-color: var(--color-background-input-store-focus);
}
.store-order .form-grid .input-txt:disabled {
  color: var(--color-font-gray4);
  border-color: var(--color-background-input-store-disabled);
  background-color: var(--color-background);
}
.store-order .form-grid .input-txt.error {
  border-color: var(--color-border-input-error);
}
.store-order .form-grid .input-txt.success + .msg {
  display: block;
  color: var(--color-primary);
}
.store-order .form-grid .input-txt.error + .msg {
  display: block;
  color: var(--color-font-red1);
}

.input-txtarea {
  padding: 16px;
  box-sizing: border-box;
  color: var(--color-white);
  resize: none;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  border-radius: 6px;
  background-color: var(--color-background-input);
  border: 1px solid var(--color-background-input);
}
.input-txtarea::placeholder {
  color: var(--color-font-gray1);
  font-weight: normal;
}
.input-txtarea:hover {
  border-color: var(--color-success);
}
.input-txtarea:focus {
  background-color: var(--color-background-input-focus);
}

.store-order .form-grid .input-select {
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 1;
  z-index: 2;
}
.store-order .form-grid .input-select .input {
  width: 100%;
  height: 46px;
  line-height: 46px;
  font-family: Pretendard;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-font-primary);
  border: 1px solid #f0f0f0;
  text-indent: 20px;
  border-radius: 4px;
  /* background: var(--color-background-input-store)
    url("assets/img/common/ico_arrow_select_open.png") right 16px center
    no-repeat; */
  background-size: 16px 16px;
}
.store-order .form-grid .input-select .input:hover {
  border-color: var(--color-primary);
}
.store-order .form-grid .input-select .input.open {
  border-color: var(--color-primary);
  /* background-image: url("assets/img/common/ico_arrow_select_close.png"); */
}
.store-order .form-grid .input-select .layer-select {
  position: absolute;
  left: 0;
  top: 52px;
  width: 100%;
  border: 0;
  background: none;
}
.store-order .form-grid .input-select .layer-select .inner {
  width: 100%;
  padding: 16px 20px 15px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px var(--color-border-gray2);
  background-color: var(--color-white);
}
.store-order .form-grid .input-select .layer-select li {
  width: 100%;
  padding: 8px 0;
}
.store-order .form-grid .input-select .layer-select li label {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}
.store-order .form-grid .input-select .layer-select li span {
  width: 100%;
  display: block;
  font-size: 14px;
  letter-spacing: -0.14px;
  line-height: 16px;
  color: var(--color-font-primary);
}
.store-order .form-grid .input-select .layer-select li label input {
  position: absolute;
  left: -20px;
  top: -20px;
  height: 1px;
  width: 1px;
}
.store-order
  .form-grid
  .input-select
  .layer-select
  li
  label
  input:checked
  + span {
  color: var(--color-primary);
}
.store-order .form-grid .input-select .layer-select .btn-close {
  display: none;
}

/*-----------------------------------------
    contents
-------------------------------------------*/

.contents {
  padding-top: 0;
}

/* page top */
.contents .page-top {
  background-color: var(--color-store-title);
}
.contents .page-top .inner {
  display: block;
  padding: 100px 0 0 0;
  height: 276px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
}
.contents .page-top .inner h2 {
  font-size: 52px;
  font-weight: 800;
  line-height: 62px;
  letter-spacing: -0.52px;
  color: var(--color-font-primary);
}
.contents .page-top .inner p {
  width: 100%;
  padding-top: 12px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.16px;
  color: var(--color-font-dark1);
}

/* product */
.product-tab {
  text-align: center;
  margin: -30px 0 100px 0;
}
.product-tab li {
  display: inline-block;
}
.product-tab .btn-tab {
  width: 208px;
  height: 60px;
  margin: 0 4px;
  line-height: 60px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-font-gray6);
  text-align: center;
  border: solid 1px var(--color-border-gray3);
  background-color: var(--color-white);
}
.product-tab .btn-tab.active {
  color: var(--color-white);
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.product-list {
  position: relative;
  width: 1216px;
  margin: 120px auto 0 auto;
}
.product-list:first-child {
  margin-top: 0;
}
.product-list .title {
  position: relative;
  margin-bottom: 32px;
}
.product-list .title .tit {
  font-size: 32px;
  font-weight: 800;
  line-height: 38px;
  letter-spacing: -0.32px;
}
.product-list .title .sub-tit {
  margin-bottom: 12px;
  opacity: 0.35;
  font-size: 14px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -0.14px;
  color: var(--color-primary);
}
.product-list .title .btn-more {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  letter-spacing: -0.14px;
  text-align: right;
  color: var(--color-font-gray2);
}

.product-list .list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px;
}
.product-list .item .info {
  position: relative;
  padding-top: 40px;
}
.product-list .item .thumb {
  width: 100%;
  margin: 0 0 14px 0;
  border-radius: 4px;
  overflow: hidden;
}
.product-list .item .thumb img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.product-list .item .thumb img {
  width: 100%;
}
.product-list .item .thumb:hover img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.product-list .item .state {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 14px;
  margin-bottom: 24px;
}
.product-list .item .state ul {
  overflow: hidden;
}
.product-list .item .state li {
  line-height: 14px;
  width: 50%;
  font-size: 12px;
  letter-spacing: -0.12px;
}
.product-list .item .state .d-day {
  float: left;
  font-weight: bold;
  color: var(--color-font-dark1);
}
.product-list .item .state .count {
  float: right;
  text-align: right;
  color: var(--color-font-gray2);
}
.product-list .item .state .count em {
  font-style: normal;
  font-weight: bold;
  color: var(--color-font-dark1);
}
.product-list .item dt a {
  color: var(--color-font-primary);
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.18px;
}
.product-list .item dt a:hover {
  text-decoration: underline;
}
.product-list .item dd {
  padding-top: 12px;
  font-size: 13px;
  letter-spacing: -0.13px;
  color: var(--color-font-gray6);
}
.product-list .item dd strong {
  font-size: 16px;
  letter-spacing: -0.16px;
  color: var(--color-font-primary);
}
.product-list .item dd .rate {
  display: inline-block;
  margin-right: 4px;
  font-weight: bold;
  font-size: 16px;
  color: var(--color-font-red1);
}

.list-filter {
  position: relative;
  overflow: hidden;
  margin-bottom: 32px;
}
.list-filter .sort li {
  float: left;
}
.list-filter .btn-sort {
  padding-left: 20px;
  padding-right: 12px;
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.16px;
  color: var(--color-font-gray6);
  border: 0;
  /* background: url("assets/img/store/ico_sort_off.png") left center no-repeat; */
  background-size: 16px 16px;
}
.list-filter .btn-sort.active {
  color: var(--color-font-primary);
  font-weight: bold;
  /* background: url(assets/img/store/ico_sort_on.png) left center no-repeat; */
  background-size: 16px 16px;
}
.list-filter .sort li:first-child .btn-sort {
  margin-right: 32px;
}
.list-filter .search {
  float: right;
  width: 280px;
  height: 47px;
  padding-right: 50px;
  border-bottom: 1px solid var(--color-border-gray2);
}
.list-filter .search input {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  font-size: 16px;
  color: var(--color-font-gray2);
  background: none;
  border: 0;
  outline: none;
}
.list-filter .search .btn-search {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 0;
  /* background: url("assets/img/common/ico_search_store.png") no-repeat; */
  background-size: 30px 30px;
}
.list-filter .search .btn-search span {
  display: none;
}
.list-filter .search input::placeholder {
  color: var(--color-font-gray2);
}

/* main */
.contents-store-main .banner-bottom {
  clear: both;
  margin: 175px 0 0 0;
  background-color: var(--color-primary);
}
.contents-store-main .banner-bottom .inner {
  position: relative;
  width: 1216px;
  height: 210px;
  margin: 0 auto;
  padding: 48px 600px 48px 0;
  box-sizing: border-box;
}
.contents-store-main .banner-bottom .inner .thumb img {
  position: absolute;
  right: 0px;
  top: -65px;
  content: "";
  display: block;
  width: 475px;
  height: auto;
}
.contents-store-main .banner-bottom .inner a {
  display: block;
}
.contents-store-main .banner-bottom h3 {
  display: inline-block;
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: -0.4px;
  color: var(--color-white);
}
.contents-store-main .banner-bottom h3 span {
  display: block;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 900;
  color: #ffe033;
}
.contents-store-main .banner-bottom p {
  display: inline-block;
  opacity: 0.6;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.16px;
  color: var(--color-white);
}

/* store detail */
.contents-store-detail .product-detail {
  width: 1216px;
  margin: 0 auto;
  padding-top: 48px;
}
.contents-store-detail .page-top {
  display: block;
  background: #fff;
  margin-bottom: 48px;
}
.contents-store-detail .page-top span {
  display: none;
}
.contents-store-detail .page-top a:before {
  content: "";
  display: inline-block;
  width: 36px;
  height: 24px;
  margin-right: 18px;
  vertical-align: middle;
  /* background: url("assets/img/common/ico_historyback_store.png") no-repeat; */
  background-size: 36px 24px;
}

.product-detail-desc {
  clear: both;
  padding-top: 80px;
}
.product-detail-desc h3 {
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.17;
  letter-spacing: -0.24px;
  border-bottom: 1px solid var(--color-border-gray2);
}
.product-detail-desc .desc {
  width: 864px;
  margin: 0 auto;
  padding: 100px 0 120px 0;
}
.product-detail-desc .desc .tit {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  line-height: 0.88;
  letter-spacing: -0.32px;
}
.product-detail-desc .desc .txt {
  margin-bottom: 60px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.56;
  letter-spacing: -0.18px;
  color: var(--color-font-dark1);
}
.product-detail-desc .desc img {
  max-width: 100%;
}
.product-detail-desc .qna {
  width: 864px;
  margin: 0 auto;
  border-bottom: 1px solid var(--color-border-gray2);
}
.product-detail-desc .qna dt {
  border-top: 1px solid var(--color-border-gray2);
}
.product-detail-desc .qna dt a {
  display: block;
  height: 89px;
  line-height: 89px;
  font-size: 18px;
  letter-spacing: -0.18px;
  color: var(--color-font-primary);
  /* background: url("assets/img/store/ico_detail_qna_open.png") right center */
  /* no-repeat; */
  background-size: 24px 24px;
}
.product-detail-desc .qna dd {
  display: none;
}

.product-detail-top:after {
  content: "";
  display: block;
  clear: both;
}
.product-detail-top .img-swiper {
  position: relative;
  float: left;
  width: 574px;
  z-index: 1;
}
.product-detail-top .img-swiper .swiper-product {
  width: 574px;
  overflow: hidden;
  border-radius: 6px;
}
.product-detail-top .img-swiper .swiper-slide img {
  width: 574px;
}
.product-detail-top .img-swiper .swiper-button-prev {
  left: 16px;
  width: 48px;
  height: 48px;
  /* background: url("assets/img/store/btn_swiper_prev.png") no-repeat; */
  background-size: 48px 48px;
}
.product-detail-top .img-swiper .swiper-button-next {
  right: 16px;
  width: 48px;
  height: 48px;
  /* background: url("assets/img/store/btn_swiper_next.png") no-repeat; */
  background-size: 48px 48px;
}
.product-detail-top .img-swiper .swiper-button-next:after,
.product-detail-top .img-swiper .swiper-button-prev:after {
  display: none;
}
.product-detail-top .img-swiper .swiper-pagination {
  bottom: -24px;
  line-height: 1;
}
.product-detail-top .img-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  opacity: 1;
  background-color: var(--color-background-bullet);
}
.product-detail-top
  .img-swiper
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.product-detail-top .product-info {
  position: relative;
  float: right;
  width: 592px;
  padding-top: 54px;
}
.product-detail-top .product-info .sns-share {
  position: absolute;
  right: 0;
  top: -7px;
}
.product-detail-top .product-info .sns-share .btn {
  height: 44px;
  line-height: 44px;
  padding: 0 0 0 52px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.16px;
  /* background: url("assets/img/store/ico_sns_share_off.png") no-repeat;
  background-size: 44px 44px; */
}
.product-detail-top .product-info .sns-share.open .btn {
  /* background-image: url("assets/img/store/ico_sns_share_on.png"); */
}
.product-detail-top .product-info .sns-share.open .layer {
  display: block;
}
.product-detail-top .product-info .sns-share .layer {
  position: absolute;
  left: 0;
  top: 48px;
  width: 108px;
  display: none;
  height: 96px;
  text-align: center;
  padding: 12px 14px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #efefef;
  background-color: #fff;
}
.product-detail-top .product-info .sns-share .layer li {
  line-height: 16px;
  padding: 8px 0;
}
.product-detail-top .product-info .sns-share .layer li a {
  font-size: 14px;
  letter-spacing: -0.14px;
  color: var(--color-font-primary);
}
.product-detail-top .product-info .sns-share .layer li a:hover {
  text-decoration: underline;
}
.product-detail-top .product-info .btn-buy {
  height: 72px;
  line-height: 72px;
  font-size: 18px;
}
.product-detail-top .product-info .tip {
  padding-top: 19px;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.13px;
  color: var(--color-font-gray2);
}
.product-detail-top .product-info .guide {
  padding: 24px 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.16px;
  border-bottom: 1px solid var(--color-border-gray2);
}
.product-detail-top .product-info .badges {
  position: absolute;
  left: 0;
  top: 0;
}
.product-detail-top .product-info .tit {
  padding: 0 120px 0 0;
  font-size: 36px;
  word-break: keep-all;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: -0.36px;
}
.product-detail-top .product-info .info-list dl:first-child {
  border-top: 0;
}
.product-detail-top .product-info .info-list dl {
  overflow: hidden;
  height: 52px;
  line-height: 52px;
  border-top: 1px solid var(--color-border-gray2);
}
.product-detail-top .product-info .info-list dt {
  position: relative;
  float: left;
  width: 70px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.14px;
}
.product-detail-top .product-info .info-list dt:before {
  position: absolute;
  left: 4px;
  top: 50%;
  width: 4px;
  height: 4px;
  display: block;
  content: "";
  margin-top: -2px;
  border-radius: 4px;
  background: #d8d8d8;
}
.product-detail-top .product-info .info-list dd {
  float: left;
  width: calc(100% - 90px);
  font-size: 16px;
  letter-spacing: -0.16px;
  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;
}
.product-detail-top .product-info .info-list dd strong {
  color: var(--color-font-primary);
}
.product-detail-top .product-info .info-list .point dd strong,
.product-detail-top .product-info .info-list .point dd {
  color: var(--color-primary);
}
.product-detail-top .product-info .info-list .ea dd {
  color: var(--color-font-gray6);
}
.product-detail-top .product-info .price-info {
  padding: 20px 0;
  font-size: 16px;
  border-bottom: 1px solid var(--color-border-dark);
}
.product-detail-top .product-info .price-info p {
  display: block;
  line-height: 34px;
  color: var(--color-font-gray2);
}
.product-detail-top .product-info .price-info .regular-point {
  margin-bottom: 12px;
  line-height: 1;
}
.product-detail-top .product-info .price-info .asop {
  display: inline-block;
  vertical-align: top;
  line-height: 34px;
  color: var(--color-primary);
}
.product-detail-top .product-info .price-info .rate {
  display: inline-block;
  vertical-align: top;
  line-height: 34px;
  margin-right: 16px;
  font-size: 28px;
  font-weight: 800;
  color: var(--color-font-red1);
}
.product-detail-top .product-info .price-info p strong {
  vertical-align: top;
  font-size: 28px;
  font-weight: 800;
  letter-spacing: -0.28px;
}
.product-detail-top .product-info .total-price {
  text-align: right;
  padding: 21px 0 48px 0;
  text-align: right;
}
.product-detail-top .product-info .total-price dt {
  display: inline-block;
  vertical-align: top;
  padding-right: 16px;
  line-height: 34px;
  font-size: 16px;
  letter-spacing: -0.16px;
  font-weight: 500;
}
.product-detail-top .product-info .total-price dd {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: -0.18px;
  color: var(--color-font-gray2);
}
.product-detail-top .product-info .total-price dd em {
  display: inline-block;
  color: var(--color-font-red1);
  vertical-align: top;
  line-height: 34px;
  font-style: normal;
}
.product-detail-top .product-info .total-price dd span {
  display: inline-block;
  vertical-align: top;
  line-height: 34px;
}
.product-detail-top .product-info .total-price dd strong {
  color: var(--color-font-red1);
  letter-spacing: -0.28px;
  font-size: 28px;
  font-weight: 800;
}

.product-detail-top .product-options {
  position: relative;
}
.product-detail-top .product-options .state {
  overflow: hidden;
  margin-top: 16px;
  width: 100%;
  height: 68px;
  line-height: 68px;
  background-color: var(--color-background-option);
}
.product-detail-top .product-options .state dt {
  float: left;
  width: 63px;
  padding: 0 0 0 20px;
  font-size: 14px;
  letter-spacing: -0.14px;
  color: var(--color-font-dark1);
}
.product-detail-top .product-options .state dd {
  float: right;
  width: calc(100% - 123px);
  padding-right: 20px;
  box-sizing: border-box;
}
.product-detail-top .product-options .state dd .input-options-select {
  width: 100%;
  height: 68px;
  line-height: 68px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: -0.14px;
  color: var(--color-font-dark1);
  text-align: left;
  border: 0;
  /* background: url("assets/img/store/btn_option_on.png") right center no-repeat; */
  background-size: 16px 16px;
}
.product-detail-top
  .product-options
  .state
  dd
  .input-options-select::placeholder {
  color: var(--color-font-dark1);
}
.product-detail-top .product-options .layer-option-select {
  position: absolute;
  left: 0;
  top: 72px;
  width: 100%;
  border: 0;
  padding: 12px 20px 12px 123px;
  box-sizing: border-box;
  background-color: var(--color-background-option);
}
.product-detail-top .product-options .layer-option-select li {
  padding: 8px 0;
  line-height: 16px;
}
.product-detail-top .product-options .layer-option-select li label {
  position: relative;
  color: var(--color-font-dark1);
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;
  display: inline-block;
  letter-spacing: -0.14px;
  font-weight: 500;
}
.product-detail-top .product-options .layer-option-select li label input {
  position: absolute;
  left: -10px;
  top: -10px;
  width: 1px;
  height: 1px;
}
.product-detail-top .product-options .layer-option-select li label:hover {
  color: var(--color-primary);
}
.product-detail-top .product-options .layer-option-select .btn-close {
  display: none;
}

.product-detail-top .quantity {
  margin-top: 16px;
  padding: 16px 20px;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  background: var(--color-background-option);
}
.product-detail-top .quantity .input-count {
  float: left;
}
.product-detail-top .quantity .input-count button {
  float: left;
  width: 36px;
  height: 36px;
  border: 0;
  background-position: center;
  background-size: 36px 36px;
}
.product-detail-top .quantity .input-count button span {
  display: none;
}
.product-detail-top .quantity .input-count .btn-minus {
  /* background-image: url("assets/img/store/btn_minus.png"); */
}
.product-detail-top .quantity .input-count .btn-plus {
  /* background-image: url("assets/img/store/btn_plus.png"); */
}
.product-detail-top .quantity .input-count input {
  float: left;
  width: 48px;
  height: 34px;
  font-size: 16px;
  outline: none;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin: 0 -1px;
  border: solid 1px #efefef;
  background-color: #fff;
}
.product-detail-top .quantity .option {
  width: 200px;
  display: inline-block;
  padding: 10px 0 10px 20px;
  line-height: 16px;
  color: var(--color-font-gray2);
  letter-spacing: -0.14px;
  font-size: 14px;
}
.product-detail-top .quantity .price {
  float: right;
  width: 165px;
  text-align: right;
  font-size: 18px;
  letter-spacing: -0.18px;
}

/* layer product buy*/
.layer-product-buy .inner {
  width: 608px;
  padding-top: 70px;
}
.layer-product-buy .product {
  position: relative;
  text-align: left;
  padding: 0 0 31px 0;
  box-sizing: border-box;
}
.layer-product-buy .product .img {
  width: 128px;
  height: 128px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}
.layer-product-buy .product .img img {
  width: 128px;
  height: 128px;
  border-radius: 12px;
}
.layer-product-buy .product h2 {
  padding: 24px 0 32px 0;
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.22px;
  text-align: center;
}
.layer-product-buy .product h2 strong {
  display: block;
  padding-bottom: 10px;
  letter-spacing: -0.26px;
  font-size: 26px;
  line-height: 32px;
}
.layer-product-buy .product p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.16px;
  color: var(--color-font-gray6);
}
.layer-product-buy .product p em {
  font-style: normal;
  color: var(--color-font-dark1);
}
.layer-product-buy .info {
  border-top: 1px solid var(--color-border-gray2);
}
.layer-product-buy .info dl {
  overflow: hidden;
  border-bottom: 1px solid var(--color-border-gray2);
}
.layer-product-buy .info dt {
  float: left;
  width: 70px;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  letter-spacing: -0.16px;
  color: var(--color-font-dark1);
}
.layer-product-buy .info dd {
  float: left;
  width: calc(100% - 70px);
  height: 46px;
  font-size: 18px;
  letter-spacing: -0.18px;
  line-height: 46px;
  text-align: right;
}
.layer-product-buy .total-price {
  overflow: hidden;
  padding: 27px 0 46px 0;
}
.layer-product-buy .total-price dt {
  float: left;
  width: 90px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: -0.16px;
}
.layer-product-buy .total-price dd {
  float: right;
  width: calc(100% - 90px);
  line-height: 20px;
  text-align: right;
  color: var(--color-font-gray2);
}
.layer-product-buy .total-price dd em {
  font-style: normal;
  color: var(--color-font-red1);
}
.layer-product-buy .total-price dd strong {
  display: inline-block;
  line-height: 34px;
  font-size: 28px;
  margin-top: -8px;
  vertical-align: top;
}
.layer-product-buy .my-point {
  position: relative;
  margin: 32px 0 40px 0;
}
.layer-product-buy .my-point .btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 98px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.14px;
  color: var(--color-white);
  border-radius: 4px;
  background: var(--color-primary);
}
.layer-product-buy .my-point h3 {
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  margin-bottom: 12px;
}
.layer-product-buy .my-point dl {
  height: 64px;
  line-height: 64px;
  padding: 0;
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.16px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 4px;
  background-color: #f8f8f8;
}
.layer-product-buy .my-point dl:first-child {
  margin-top: 0;
  font-size: 16px;
  letter-spacing: -0.16px;
}
.layer-product-buy .my-point dt {
  float: left;
  width: 50%;
  height: 64px;
  box-sizing: border-box;
  padding-left: 24px;
  color: var(--color-font-dark1);
  box-sizing: border-box;
}
.layer-product-buy .my-point dt img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 40px;
  vertical-align: middle;
}
.layer-product-buy .my-point dd {
  float: left;
  width: 50%;
  height: 64px;
  box-sizing: border-box;
  font-size: 18px;
  letter-spacing: -0.18px;
  padding-right: 24px;
  box-sizing: border-box;
  text-align: right;
}
.layer-product-buy hr {
  width: calc(100% + 120px);
  margin: 0 -60px;
  height: 0;
  border: 0;
  border-top: 1px solid var(--color-border-gray3);
}
.layer-product-buy .btn-bottom {
  text-align: center;
}
.layer-product-buy .btn-cancel,
.layer-product-buy .btn-vote {
  width: 176px;
  height: 52px;
  font-size: 16px;
  text-align: center;
  border: 0;
  font-weight: bold;
  color: var(--color-white);
  margin: 0 auto;
  border-radius: 26px;
  background-color: var(--color-primary);
}
.layer-product-buy .btn-cancel {
  background-color: #9aa2b5;
}

/* order */
.store-order {
  width: 1216px;
  margin: 0 auto;
  padding: 98px 0 0 0;
}
.store-order .page-top {
  display: none;
}
.store-order .page-tit {
  padding-bottom: 120px;
  text-align: center;
  font-size: 52px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -0.52px;
}
.store-order .section-tit {
  height: 24px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.2px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-border-dark);
}
.store-order .order-info {
  position: relative;
  width: 1216px;
  display: grid;
}
.store-order .order-info:after {
  content: "";
  display: block;
  clear: both;
}

.store-order .product-list {
  margin-bottom: 110px;
}
.store-order .product-list .item {
  height: 192px;
  margin-top: 52px;
  display: grid;
  text-align: center;
  align-items: stretch;
  grid-template-columns: 1fr 168px 132px 168px 208px;
  border-radius: 6px;
  border: solid 1px var(--color-border-gray2);
}
.store-order .product-list .item dl {
  position: relative;
}
.store-order .product-list .item dt {
  display: none;
  position: absolute;
  left: 0;
  top: -52px;
  width: 100%;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.14px;
  color: var(--color-font-gray6);
}
.store-order .product-list .item:first-child dt {
  display: block;
}
.store-order .product-list .item dd {
  height: 100%;
  font-size: 16px;
  letter-spacing: -0.16px;
  color: var(--color-font-primary);
  box-sizing: border-box;
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 32px 21px 32px 21px;
}
.store-order .product-list .item .product {
  height: 128px;
}
.store-order .product-list .item .product dd {
  padding-left: 40px;
  min-height: 192px;
  justify-content: left;
  text-align: left;
}
.store-order .product-list .item .product dd strong {
  font-size: 18px;
  letter-spacing: -0.18px;
  line-height: 1.56;
}
.store-order .product-list .item .product .thumb {
  width: 128px;
  height: 128px;
  margin: 0;
  vertical-align: top;
  margin-right: 32px;
  border-radius: 12px;
  overflow: hidden;
}
.store-order .product-list .item .product .thumb img {
  width: 128px;
  height: 128px;
}
.store-order .product-list .item .product-price em {
  font-style: normal;
  font-size: 14px;
  letter-spacing: -0.14px;
  color: var(--color-font-red1);
}
.store-order .product-list .item .product-price em span {
  display: block;
  font-size: 14px;
  line-height: 2;
  letter-spacing: -0.14px;
  color: var(--color-font-gray6);
}
.store-order .product-list .item .product-price strong {
  font-size: 16px;
  letter-spacing: -0.16px;
  color: var(--color-font-red1);
}

.store-order .order-info .customer-info {
  width: 800px;
  padding-bottom: 110px;
  grid-row: 1/2;
}
.store-order .order-info .shipping-info {
  width: 800px;
  padding-bottom: 110px;
  grid-row: 2/3;
}
.store-order .order-info .coupon-info {
  width: 800px;
  padding-bottom: 110px;
  grid-row: 3/4;
}
.store-order .order-info .agree-info {
  width: 800px;
  grid-row: 4/5;
}

.store-order .order-info .coupon-info .guide {
  color: var(--color-font-primary);
}
.store-order .order-info .coupon-info .cell:first-child {
  margin-bottom: 24px;
}

.store-order .order-info .form-wrap {
  padding-top: 32px;
}
.store-order .order-info .form-wrap .cell {
  position: relative;
  padding: 8px 0 8px 176px;
}
.store-order .order-info .form-wrap .grid-tit {
  position: absolute;
  left: 0;
  top: 8px;
  height: 48px;
  line-height: 48px;
  font-weight: normal;
  letter-spacing: -0.16px;
  font-size: 16px;
}
.store-order .order-info .form-wrap .grid-tit .ico-required {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  color: var(--color-font-red1);
  font-style: normal;
}

.store-order .order-info .payment-info {
  position: sticky;
  top: 160px;
  width: 368px;
  height: 340px;
  padding-left: 40px;
  grid-row: 1/2;
}
.store-order .order-info .payment-info .section-tit {
  color: var(--color-primary);
  border: 0;
}
.store-order .order-info .payment-info .box {
  width: 366px;
  height: auto;
  padding: 30px 24px 8px 24px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px rgba(90, 32, 255, 0.6);
}
.store-order .order-info .payment-info dl {
  overflow: hidden;
  padding-bottom: 24px;
  line-height: 20px;
}
.store-order .order-info .payment-info dl:last-child {
  padding-bottom: 26px;
}
.store-order .order-info .payment-info dt {
  float: left;
  width: 80px;
  font-size: 14px;
  letter-spacing: -0.14px;
}
.store-order .order-info .payment-info dd {
  float: right;
  width: 200px;
  text-align: right;
  font-size: 16px;
  letter-spacing: -0.16px;
}
.store-order .order-info .payment-info .total {
  padding-top: 30px;
  line-height: 22px;
  border-top: 1px solid var(--color-border-gray2);
}
.store-order .order-info .payment-info .total dt {
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.16px;
}
.store-order .order-info .payment-info .total dd {
  line-height: 22px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.18px;
}
.store-order .order-info .payment-info .total span {
  display: block;
  height: 16px;
  text-align: right;
  font-weight: normal;
  color: var(--color-font-gray6);
  line-height: 2;
  letter-spacing: -0.14px;
  font-size: 14px;
}

.store-order .agree-info {
  padding: 0 0 80px 0;
}
.store-order .agree-info ul {
  padding-top: 17px;
}
.store-order .agree-info li {
  position: relative;
  padding: 24px 0 24px 0;
  font-size: 16px;
  letter-spacing: -0.16px;
  border-bottom: 1px solid var(--color-border-gray2);
}
.store-order .agree-info li a {
  position: absolute;
  right: 0;
  top: 50%;
  width: 16px;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  text-indent: -20px;
  font-size: 0;
  /* background: url("assets/img/store/btn_agree.png") no-repeat; */
  background-size: 16px 20px;
}
.store-order .agree-info .input-checkbox {
  margin-right: 12px;
}
.store-order .agree-info p {
  padding-top: 34px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  letter-spacing: -0.16px;
}
.store-order .btns {
  padding-top: 80px;
  text-align: center;
  border-top: 1px solid var(--color-border-gray2);
}
.store-order .btns .btn {
  width: 488px;
  height: 72px;
  line-height: 72px;
  margin: 0 auto;
  font-size: 18px;
  border-radius: 12px;
}
.store-order .cell .guide {
  padding-top: 16px;
  line-height: 1;
  letter-spacing: -0.14px;
  font-size: 14px;
  color: var(--color-font-gray6);
}
.store-order .form-grid .dash,
.store-order .form-grid .at {
  text-align: center;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  font-weight: 500;
}
.store-order .form-grid .btn {
  display: inline-block;
  width: 124px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  font-weight: 500;
}
.store-order .form-hp {
  display: grid;
  grid-template-columns: 1fr 24px 1fr 24px 1fr;
}
.store-order .form-email {
  display: grid;
  grid-template-columns: 1fr 32px 1fr;
}
.store-order .form-address-check {
  padding: 14px 0;
}
.store-order .form-address-check .input-checkbox {
  margin: 0 48px 0 0;
  line-height: 18px;
  display: inline-block;
}
.store-order .form-address-check label {
  margin: 0 12px 0 0;
}
.store-order .form-address-save {
  padding: 20px 0 24px 0;
}
.store-order .form-address-save .input-checkbox {
  line-height: 18px;
  display: inline-block;
}
.store-order .form-address-save label {
  margin: 0 12px 0 0;
}
.store-order .form-address {
  margin-top: 12px;
}
.store-order .form-zipcode .input {
  width: 208px;
  display: inline-block;
  vertical-align: top;
}
.store-order .form-zipcode .btn {
  margin-left: 8px;
  vertical-align: top;
}
.store-order .form-coupon {
  display: grid;
  grid-template-columns: 1fr 124px;
  gap: 12px;
}
.store-order .input-select .layer-select:before {
  display: none;
}
.store-order .form-grid .input-select {
  z-index: 1;
}
.store-order .form-grid .input-select .layer-select {
  height: auto;
}
.store-order .form-grid .layer-select .inner {
  position: static;
  left: initial;
  bottom: initial;
  text-align: left;
}
.store-order .form-grid .layer-select .inner li {
  text-align: left;
}

/*-----------------------------------------
    mobile
-------------------------------------------*/
@media all and (max-width: 1023px) {
  .header .logo {
    /* background-image: url("assets/img/common/logo_header_m_store.png"); */
  }
  .header button.btn-menu-open {
    /* background-image: url("assets/img/common/btn_header_menu_store.png"); */
  }

  /* common */
  .product-detail .page-top,
  .store-order .page-top {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 60px;
    padding: 19px 20px;
    box-sizing: border-box;
    background: var(--color-background);
    z-index: 999;
  }
  .product-detail .page-top .btn-historyback,
  .store-order .page-top .btn-historyback {
    display: inline-block;
    width: 26px;
    height: 18px;
    /* background: url("assets/img/common/btn_historyback2_store.png") no-repeat; */
    background-size: 26px 18px;
  }
  .product-detail .page-top .btn-historyback:before,
  .store-order .page-top .btn-historyback:before {
    display: none !important;
  }
  .product-detail .page-top .page-title,
  .store-order .page-top .page-title {
    display: inline-block;
    margin-left: 4px;
    font-size: 16px;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: -0.16px;
    color: var(--color-white);
  }
  .product-detail .page-top.fixed,
  .store-order .page-top.fixed {
    background: var(--color-background);
  }

  .layer-popup > .inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    transform: none;
    border-radius: 0;
    box-sizing: border-box;
  }
  .layer-popup .title h3 {
    margin-top: 72px;
    font-size: 26px;
    letter-spacing: -0.26px;
  }
  .layer-popup .btn-layer-close {
    right: 15px;
    top: 14px;
  }
  .layer-popup .layer-top {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background: var(--color-white);
  }

  .store-order .form-grid .input-txt {
    text-indent: 12px;
  }

  /* page top */
  .contents {
    padding-top: 0;
  }
  .contents .page-top .inner {
    display: block;
    padding: 48px 20px 58px 20px;
    height: auto;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
  }
  .contents .page-top .inner h2 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.32px;
  }
  .contents .page-top .inner p {
    font-size: 14px;
    letter-spacing: -0.14px;
  }

  /* product */
  .product-tab {
    margin: -26px 0 60px 0;
    padding: 0 20px;
  }
  .product-tab ul {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .product-tab .btn-tab {
    float: left;
    width: 156px;
    height: 52px;
    margin: 0 4px;
    line-height: 52px;
    font-size: 15px;
  }

  .product-list {
    width: 100%;
    margin: 100px auto 0 auto;
  }
  .product-list:first-child {
    margin-top: 0;
  }
  .product-list .title {
    padding: 0 70px 0 20px;
    margin-bottom: 20px;
  }
  .product-list .title .tit {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.2px;
  }
  .product-list .title .sub-tit {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.12px;
  }
  .product-list .title .btn-more {
    right: 20px;
    font-size: 13px;
    letter-spacing: -0.13px;
  }

  .product-list .list {
    display: block;
    padding: 0 20px;
  }
  .product-list .item .thumb {
    width: 100%;
    margin: 0 0 16px 0;
    border-radius: 4px;
    overflow: hidden;
  }
  .product-list .item .thumb img {
    width: 100%;
  }
  .product-list .item .state {
    margin-bottom: 18px;
  }
  .product-list .item dt a {
    font-size: 16px;
    letter-spacing: -0.16px;
  }
  .product-list .item dd {
    padding-top: 10px;
    font-size: 12px;
    letter-spacing: -0.12px;
  }
  .product-list .item dd strong {
    font-size: 14px;
    letter-spacing: -0.14px;
  }
  .product-list .item dd .rate {
    font-size: 14px;
  }

  .list-filter .sort {
    padding-left: 20px;
  }
  .list-filter .sort li {
    float: left;
  }
  .list-filter .btn-sort {
    padding-left: 22px;
    padding-right: 6px;
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: -0.14px;
    /* background: url("assets/img/store/ico_sort_off_m.png") left center no-repeat; */
    background-size: 16px 16px;
  }
  .list-filter .sort li:first-child .btn-sort {
    margin-right: 0;
  }
  .list-filter .btn-sort.active {
    /* background: url("assets/img/store/ico_sort_on_m.png") left center no-repeat; */
    background-size: 16px 16px;
  }
  .list-filter .search {
    position: relative;
    left: inherit;
    top: inherit;
    float: right;
    width: calc(50% - 30px);
    margin-right: 20px;
    box-sizing: border-box;
    height: 30px;
    padding-right: 50px;
  }
  .list-filter .search input {
    width: 100%;
    height: 26px;
    line-height: 26px;
    font-weight: 500;
    font-size: 14px;
    background: none;
    border: 0;
    outline: none;
  }
  .list-filter .search .btn-search {
    width: 26px;
    height: 26px;
    border: 0;
    /* background: url("assets/img/common/ico_search_store_m.png") no-repeat; */
    background-size: 26px 26px;
  }

  /* main */
  .contents-store-main .product-list .item {
    position: relative;
    margin-top: 16px;
    min-height: 100px;
    padding-left: 116px;
  }
  .contents-store-main .product-list .item .info {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 100px;
    align-items: center;
  }
  .contents-store-main .product-list .item .thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
  }
  .contents-store-main .product-list .item .state {
    position: relative;
    left: initial;
    top: initial;
    padding-top: 9px;
    width: 100%;
    min-height: 0;
  }
  .contents-store-main .product-list .item li {
    padding-top: 0;
    min-height: auto;
  }
  .contents-store-main .product-list .item:first-child .info {
    display: block;
    min-height: auto;
  }
  .contents-store-main .product-list .item:first-child dt a {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.18px;
  }
  .contents-store-main .product-list .item:first-child dd {
    padding-top: 10px;
  }
  .contents-store-main .product-list .item:first-child {
    margin-top: 0;
    padding: 0 0 4px 0;
  }
  .contents-store-main .product-list .item:first-child:after {
    margin-top: 24px;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--color-border-gray2);
  }
  .contents-store-main .product-list .item:first-child .thumb {
    position: relative;
    left: initial;
    top: initial;
    width: 100%;
    height: auto;
  }

  .contents-store-main .banner-bottom {
    margin: 100px 0 0 0;
    padding: 43px 0 13px 0;
    background-color: var(--color-primary);
  }
  .contents-store-main .banner-bottom .inner {
    width: 100%;
    height: auto;
    padding: 0;
    word-break: keep-all;
  }
  .contents-store-main .banner-bottom .inner .thumb img {
    position: relative;
    right: initial;
    top: initial;
    width: 100%;
    height: auto;
  }
  .contents-store-main .banner-bottom h3 {
    display: block;
    padding: 0 20px;
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 1.17;
    letter-spacing: -0.24px;
  }
  .contents-store-main .banner-bottom h3 span {
    display: block;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 1;
  }
  .contents-store-main .banner-bottom p {
    display: block;
    padding: 0 20px;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.14px;
  }

  /* store list */
  .contents-store-list .product-list {
    margin-top: 50px;
  }
  .contents-store-list .product-list .item .info {
    padding-top: 32px;
  }
  .contents-store-list .product-list .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 42px 10px;
    padding: 0 20px;
  }
  .contents-store-list .product-list .item .thumb {
    margin: 0 0 14px 0;
  }
  .contents-store-list .product-list .item .thumb img {
    width: 100%;
  }
  .contents-store-list .product-list .item .state {
    margin-bottom: 18px;
  }
  .contents-store-list .product-list .item dt a {
    font-size: 16px;
    letter-spacing: -0.16px;
  }
  .contents-store-list .product-list .item dd {
    padding-top: 10px;
    font-size: 12px;
    letter-spacing: -0.12px;
  }
  .contents-store-list .product-list .item dd strong {
    font-size: 14px;
    letter-spacing: -0.14px;
  }
  .contents-store-list .product-list .item dd .rate {
    font-size: 14px;
    display: block;
  }

  /* store detail */
  .contents-store-detail .product-detail {
    width: 100%;
    padding-top: 60px;
  }

  .product-detail-desc {
    clear: both;
    padding: 50px 20px 60px 20px;
    border-top: 10px solid #f8f8f8;
  }
  .product-detail-desc h3 {
    padding-bottom: 10px;
    font-size: 18px;
    line-height: 1.17;
    letter-spacing: -0.18px;
  }
  .product-detail-desc .desc {
    width: 100%;
    margin: 0 auto;
    padding: 32px 0 80px 0;
  }
  .product-detail-desc .desc .tit {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 1.4;
    letter-spacing: -0.2px;
  }
  .product-detail-desc .desc .txt {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.14px;
    text-align: center;
  }
  .product-detail-desc .desc img {
    max-width: 100%;
  }
  .product-detail-desc .qna {
    width: 100%;
  }
  .product-detail-desc .qna dt a {
    height: 61px;
    line-height: 61px;
    font-size: 16px;
    letter-spacing: -0.16px;
    background-size: 20px 20px;
  }

  .product-detail-top .img-swiper {
    position: relative;
    float: none;
    width: 100%;
    padding-bottom: 22px;
  }
  .product-detail-top .img-swiper .swiper-product {
    width: 100%;
    overflow: hidden;
    border-radius: 6px;
  }
  .product-detail-top .img-swiper .swiper-slide img {
    width: 100%;
  }
  .product-detail-top .img-swiper .swiper-button-prev {
    display: none;
  }
  .product-detail-top .img-swiper .swiper-button-next {
    display: none;
  }
  .product-detail-top .img-swiper .swiper-pagination {
    bottom: 0;
    line-height: 1;
  }
  .product-detail-top .img-swiper .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 4px;
  }

  .product-detail-top .product-info {
    position: relative;
    float: none;
    width: 100%;
    margin-top: 37px;
    padding: 45px 20px 50px 20px;
    box-sizing: border-box;
  }
  .product-detail-top .product-info .sns-share {
    right: 20px;
    top: -5px;
  }
  .product-detail-top .product-info .sns-share .btn {
    width: 38px;
    height: 38px;
    padding: 0;
    font-size: 0px;
    text-indent: -20px;
    background-size: 38px 38px;
  }
  .product-detail-top .product-info .sns-share .layer {
    left: initial;
    right: 0;
    top: 42px;
    width: 92px;
    display: none;
    height: 96px;
  }
  .product-detail-top .product-info .btn-buy {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 52px;
    line-height: 52px;
    font-size: 16px;
    border-radius: 0;
  }
  .product-detail-top .product-info .tip {
    margin-top: 18px;
    padding-top: 16px;
    text-align: left;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: -0.12px;
    border-top: 1px solid var(--color-border-gray2);
  }
  .product-detail-top .product-info .guide {
    padding: 20px 0;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: -0.14px;
  }
  .product-detail-top .product-info .badges {
    position: absolute;
    left: 20px;
    top: 0;
    margin-bottom: 24px;
  }
  .product-detail-top .product-info .tit {
    padding: 0;
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: -0.24px;
  }

  .product-detail-top .product-info .info-list dl {
    height: auto;
    line-height: auto;
    padding: 14px 0;
    line-height: 1.2;
    border-top: 1px solid var(--color-border-gray2);
  }
  .product-detail-top .product-info .info-list dt {
    width: 75px;
    padding-left: 10px;
    font-size: 14px;
    letter-spacing: -0.14px;
  }
  .product-detail-top .product-info .info-list dt:before {
    left: 0;
  }
  .product-detail-top .product-info .info-list dd {
    width: calc(100% - 85px);
    font-size: 14px;
    padding: 0;
    letter-spacing: -0.14px;
  }
  .product-detail-top .product-info .info-list .date dd span {
    display: block;
    text-align: right;
    line-height: 1.57;
    color: var(--color-font-dark1);
  }
  .product-detail-top .product-info .price-info {
    padding: 10px 0 16px 0;
    font-size: 16px;
    border-bottom: 1px solid var(--color-border-dark);
  }

  .product-detail-top .product-info .price-info .regular-point {
    margin-bottom: 12px;
  }
  .product-detail-top .product-info .price-info .rate {
    margin-right: 5px;
    font-size: 20px;
  }
  .product-detail-top .product-info .price-info p strong {
    font-size: 20px;
    letter-spacing: -0.2px;
  }

  .product-detail-top .product-info .total-price {
    text-align: right;
    padding: 22px 0 0px 0;
    text-align: right;
  }
  .product-detail-top .product-info .total-price dt {
    float: left;
    line-height: 20px;
    padding: 2px 0 0 0;
  }
  .product-detail-top .product-info .total-price dd {
    display: inline;
    vertical-align: middle;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
  .product-detail-top .product-info .total-price dd em {
    vertical-align: middle;
    line-height: 20px;
    padding-right: 0;
  }
  .product-detail-top .product-info .total-price dd span {
    display: block;
    padding-top: 6px;
    line-height: 20px;
  }
  .product-detail-top .product-info .total-price dd strong {
    vertical-align: middle;
    line-height: 20px;
    letter-spacing: -0.2px;
    font-size: 20px;
  }

  .product-detail-top .product-options {
    position: relative;
    padding-top: 18px;
  }
  .product-detail-top .product-options .state {
    overflow: hidden;
    margin-top: 0;
    width: 100%;
    height: 64px;
    line-height: 64px;
  }
  .product-detail-top .product-options .state dt {
    float: left;
    width: 48px;
    padding: 0 0 0 12px;
  }
  .product-detail-top .product-options .state dd {
    float: right;
    width: calc(100% - 60px);
    padding-right: 12px;
    box-sizing: border-box;
  }
  .product-detail-top .product-options .state dd .input-options-select {
    width: 100%;
    height: 64px;
    line-height: 64px;
    vertical-align: top;
  }
  .product-detail-top .product-options .layer-option-select {
    position: absolute;
    left: 0;
    top: 72px;
    width: 100%;
    border: 0;
    padding: 12px 20px 12px 60px;
    box-sizing: border-box;
    background-color: var(--color-background-option);
  }

  .product-detail-top .quantity {
    margin-top: 12px;
    padding: 16px 12px;
    height: auto;
    min-height: 36px;
    line-height: 36px;
    overflow: hidden;
    background: var(--color-background-option);
  }
  .product-detail-top .quantity .input-count button {
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
  }
  .product-detail-top .quantity .input-count input {
    width: 45px;
    height: 30px;
    font-size: 14px;
  }
  .product-detail-top .quantity .option {
    float: left;
    width: 100%;
    padding: 0 0 12px 0;
    line-height: 16px;
  }
  .product-detail-top .quantity .price {
    float: right;
    width: 165px;
    line-height: 32px;
    text-align: right;
  }

  /* layer product buy */
  .layer-product-buy .inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 72px 20px;
  }

  .layer-product-buy .product {
    padding: 0;
  }
  .layer-product-buy .product .img {
    width: 120px;
    height: 120px;
  }
  .layer-product-buy .product .img img {
    width: 120px;
    height: 120px;
    border-radius: 8px;
  }
  .layer-product-buy .product h2 {
    padding: 24px 0 48px 0;
    font-size: 20px;
    letter-spacing: -0.2px;
  }
  .layer-product-buy .product h2 strong {
    padding-bottom: 8px;
    line-height: 30px;
  }
  .layer-product-buy .product p {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.16px;
    color: var(--color-font-gray6);
  }
  .layer-product-buy .product p em {
    font-style: normal;
    color: var(--color-font-dark1);
  }
  .layer-product-buy .info {
    border-top: 1px solid var(--color-border-gray2);
  }
  .layer-product-buy .info dl {
    overflow: hidden;
    border-bottom: 1px solid var(--color-border-gray2);
  }
  .layer-product-buy .info dt {
    float: left;
    width: 70px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    letter-spacing: -0.14px;
  }
  .layer-product-buy .info dd {
    float: left;
    width: calc(100% - 70px);
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    letter-spacing: -0.16px;
    text-align: right;
  }
  .layer-product-buy .total-price {
    padding: 14px 0 30px 0;
  }
  .layer-product-buy .total-price dt {
    float: left;
    width: 90px;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.14px;
  }
  .layer-product-buy .total-price dd {
    line-height: 28px;
    font-size: 14px;
    letter-spacing: -0.14px;
  }
  .layer-product-buy .total-price dd strong {
    display: inline;
    line-height: 28px;
    font-size: 22px;
    letter-spacing: -0.22px;
    margin-top: 0;
    vertical-align: baseline;
  }
  .layer-product-buy .total-price dd span {
    display: block;
    text-align: right;
    line-height: 1;
    padding-top: 6px;
  }
  .layer-product-buy .my-point {
    position: relative;
    margin: 40px 0 40px 0;
  }
  .layer-product-buy .my-point .btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 98px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.14px;
    color: var(--color-white);
    border-radius: 4px;
    background: var(--color-primary);
  }
  .layer-product-buy .my-point h3 {
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    margin-bottom: 12px;
  }
  .layer-product-buy .my-point dl {
    height: 52px;
    line-height: 52px;
    padding: 0;
    margin-top: 8px;
    font-size: 16px;
  }
  .layer-product-buy .my-point dl:first-child {
    margin-top: 0;
    font-size: 16px;
    letter-spacing: -0.16px;
  }
  .layer-product-buy .my-point dt {
    float: left;
    width: 50%;
    height: 52px;
    padding-left: 16px;
    font-size: 16px;
    letter-spacing: -0.16px;
  }
  .layer-product-buy .my-point dt img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  .layer-product-buy .my-point dd {
    float: left;
    width: 50%;
    height: 52px;
    font-size: 16px;
    letter-spacing: -0.16px;
    padding-right: 16px;
    box-sizing: border-box;
    text-align: right;
  }
  .layer-product-buy hr {
    width: calc(100% + 40px);
    margin: 0 -40px;
  }

  .layer-product-buy .btn-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .layer-product-buy .btn-cancel,
  .layer-product-buy .btn-vote {
    float: left;
    width: 50%;
    border-radius: 0;
  }
  .layer-product-buy .btn-layer-close {
    position: absolute;
    left: 20px;
    top: 21px;
    width: 26px;
    height: 18px;
    /* background: url("assets/img/common/btn_historyback_m.png") no-repeat; */
    background-size: 26px 18px;
  }

  /* order */
  .store-order {
    width: 100%;
    margin: 0 auto;
    padding: 84px 20px 0 20px;
    box-sizing: border-box;
  }
  .store-order .page-top {
    display: block;
    background: var(--color-white);
  }
  .store-order .page-top .btn-historyback {
    font-size: 0px;
    overflow: hidden;
    text-indent: -20px;
  }
  .store-order .page-tit {
    padding-bottom: 48px;
    font-size: 32px;
    letter-spacing: -0.32px;
  }
  .store-order .section-tit {
    height: 24px;
    line-height: 24px;
    font-size: 18px;
    letter-spacing: -0.18px;
    padding-bottom: 10px;
  }
  .store-order .order-info {
    position: relative;
    width: 100%;
    display: block;
  }

  .store-order .product-list {
    margin-bottom: 80px;
  }
  .store-order .product-list .item {
    height: auto;
    margin-top: 0;
    display: block;
    text-align: center;
    align-items: initial;
    padding-bottom: 7px;
    border-radius: 0;
    border: 0;
    border-bottom: solid 1px var(--color-border-gray2);
  }
  .store-order .product-list .item dl {
    position: relative;
    padding: 9px 0;
    height: auto;
    overflow: hidden;
  }
  .store-order .product-list .item dt {
    float: left;
    line-height: 20px;
    height: 20px;
    width: 120px;
    display: block;
    position: relative;
    left: initial;
    top: initial;
    text-align: left;
  }
  .store-order .product-list .item:first-child dt {
    display: block;
  }
  .store-order .product-list .item dd {
    float: left;
    display: inline;
    width: calc(100% - 120px);
    line-height: 20px;
    min-height: 20px;
    text-align: right;
    font-size: 16px;
    letter-spacing: -0.16px;
    padding: 0;
    justify-content: initial;
  }
  .store-order .product-list .item .product {
    height: auto;
    padding: 0;
    margin-bottom: 7px;
    border-bottom: solid 1px var(--color-border-gray2);
  }
  .store-order .product-list .item .product dt {
    display: none;
  }
  .store-order .product-list .item .product dd {
    float: initial;
    display: flex;
    padding-left: 0;
    width: 100%;
    height: 140px;
    min-height: 100px;
    justify-content: left;
    text-align: left;
  }
  .store-order .product-list .item .product dd strong {
    font-size: 16px;
    letter-spacing: -0.16px;
    line-height: 1.3;
  }
  .store-order .product-list .item .product .thumb {
    width: 100px;
    height: 100px;
    margin: 0;
    margin-right: 17px;
    border-radius: 8px;
  }
  .store-order .product-list .item .product .thumb img {
    width: 100px;
    height: 100px;
  }
  .store-order .product-list .item .product-price em {
    font-style: normal;
    font-size: 14px;
    letter-spacing: -0.14px;
    color: var(--color-font-red1);
  }
  .store-order .product-list .item .product-price em span {
    display: block;
    font-size: 14px;
    line-height: 2;
    letter-spacing: -0.14px;
    color: var(--color-font-gray6);
  }
  .store-order .product-list .item .product-price strong {
    font-size: 16px;
    letter-spacing: -0.16px;
    color: var(--color-font-red1);
  }

  .store-order .order-info .customer-info {
    width: 100%;
    padding-bottom: 50px;
  }
  .store-order .order-info .shipping-info {
    width: 100%;
    padding-bottom: 50px;
  }
  .store-order .order-info .coupon-info {
    width: 100%;
    padding-bottom: 50px;
  }
  .store-order .order-info .agree-info {
    width: 100%;
  }

  .store-order .order-info .coupon-info .cell:first-child {
    margin-bottom: 0px;
  }

  .store-order .order-info .form-wrap {
    padding-top: 22px;
  }
  .store-order .order-info .form-wrap .cell {
    position: relative;
    padding: 8px 0 30px 0;
  }
  .store-order .order-info .form-wrap .grid-tit {
    position: relative;
    left: initial;
    top: initial;
    margin-bottom: 12px;
    height: 16px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: -0.14px;
    font-size: 14px;
  }
  .store-order .order-info .form-wrap .grid-tit .ico-required {
    margin-left: 2px;
  }

  .store-order .order-info .payment-info {
    position: relative;
    top: initial;
    width: 100%;
    height: auto;
    margin-bottom: 80px;
    padding-left: 0;
  }
  .store-order .order-info .payment-info .box {
    width: 100%;
    height: auto;
    padding: 24px 16px 8px 16px;
    box-sizing: border-box;
    border-radius: 8px;
    border: solid 2px rgba(90, 32, 255, 0.6);
  }
  .store-order .order-info .payment-info dl {
    padding-bottom: 16px;
    line-height: 20px;
  }
  .store-order .order-info .payment-info dl:last-child {
    padding-bottom: 16px;
  }
  .store-order .order-info .payment-info dd {
    float: right;
    width: calc(100% - 80px);
  }
  .store-order .order-info .payment-info .total {
    margin-top: 4px;
    padding-top: 21px;
    line-height: 22px;
    border-top: 1px solid var(--color-border-gray2);
  }
  .store-order .order-info .payment-info .total dt {
    line-height: 22px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.16px;
  }
  .store-order .order-info .payment-info .total dd {
    line-height: 22px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.18px;
  }
  .store-order .order-info .payment-info .total span {
    display: block;
    height: 16px;
    text-align: right;
    font-weight: normal;
    color: var(--color-font-gray6);
    line-height: 2;
    letter-spacing: -0.14px;
    font-size: 14px;
  }

  .store-order .agree-info {
    padding: 0 0 52px 0;
  }
  .store-order .agree-info ul {
    padding-top: 6px;
  }
  .store-order .agree-info li {
    position: relative;
    padding: 24px 0 24px 0;
    font-size: 14px;
    letter-spacing: -0.14px;
    border-bottom: 1px solid var(--color-border-gray2);
  }
  .store-order .agree-info li a {
    position: absolute;
    right: 0;
    top: 50%;
    width: 16px;
    height: 20px;
    margin-top: -10px;
    overflow: hidden;
    text-indent: -20px;
    font-size: 0;
    /* background: url("assets/img/store/btn_agree.png") no-repeat; */
    background-size: 16px 20px;
  }
  .store-order .agree-info .input-checkbox {
    margin-right: 10px;
  }
  .store-order .agree-info p {
    padding-top: 20px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.14px;
  }
  .store-order .btns {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    border-top: 0;
    z-index: 2;
  }
  .store-order .btns .btn {
    width: 100%;
    height: 52px;
    line-height: 52px;
    margin: 0 auto;
    font-size: 16px;
    border-radius: 0;
  }
  .store-order .cell .guide {
    line-height: 1.54;
    letter-spacing: -0.13px;
    font-size: 13px;
  }
  .store-order .form-grid .dash,
  .store-order .form-grid .at {
    text-align: center;
    display: inline-block;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    font-weight: 500;
  }
  .store-order .form-grid .btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 500;
  }
  .store-order .form-hp {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .store-order .form-hp .dash {
    display: none;
  }
  .store-order .form-email {
    display: grid;
    grid-template-columns: 1fr 32px 1fr;
  }
  .store-order .form-address-check {
    padding: 12px 0 0 0;
    margin-bottom: -2px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .store-order .form-address-check .input-checkbox {
    margin: 0 0 0 0;
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 18px;
    display: inline-block;
  }
  .store-order .form-address-check label {
    margin: 0 10px 0 0;
  }
  .store-order .form-address-save {
    padding: 24px 0 24px 0;
  }
  .store-order .form-address-save .input-checkbox {
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 18px;
    display: inline-block;
  }
  .store-order .form-address-save label {
    margin: 0 10px 0 0;
  }
  .store-order .form-address {
    margin-top: 12px;
  }
  .store-order .form-zipcode {
    display: grid;
    grid-template-columns: 1fr 110px;
    gap: 10px;
  }
  .store-order .form-zipcode .input {
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  .store-order .form-zipcode .btn {
    margin-left: 0;
    vertical-align: top;
  }
  .store-order .form-coupon {
    grid-template-columns: 1fr 82px;
    gap: 10px;
  }
}
