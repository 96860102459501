@import url("assets/font/pretendard/pretendard.css");
@import url("assets/font/montserrat/montserrat.css");

/*-----------------------------------------
    reset
-------------------------------------------*/

:root {
  --color-white: #fff;

  --color-font-primary: #4e4e4e;
  --color-font-secondary: #131313;
  --color-font-green: #2bc48c;

  --color-border-layout: #e5e5e5;
  --color-border-content: #e1e1e1;
  --color-border-content2: #efefef;
  --color-border-content3: #f4f4f4;
}

* {
  margin: 0;
  padding: 0;
  font-family: Pretendard;
}
ul,
li {
  list-style: none;
}
img {
  border: 0;
  vertical-align: top;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
textarea {
  resize: none;
}
button {
  cursor: pointer;
}
html {
  min-height: 100%;
}
body {
  line-height: 1.4;
  min-width: 1192px;
  min-height: 100%;
  color: var(--color-font-primary);
}
.hide_obj {
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  text-indent: 10px;
}
.move {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.no_scroll {
  overflow: hidden;
}
.no_scroll body {
  overflow: hidden;
}

.selectbox {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

input,
textarea,
button {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
table caption {
  display: none;
}

/*-----------------------------------------
    layout
-------------------------------------------*/
body .wrap {
  min-height: 100vh;
}

/* header */
.header {
  position: fixed;
  width: 100%;
  z-index: 3;
}
.header .inner {
  position: relative;
  min-width: 1264px;
  height: 120px;
  text-align: right;
  margin: 0 auto;
  padding-left: 0;
  box-sizing: border-box;
}
.header h1 {
  position: absolute;
  left: 120px;
  top: 44px;
  line-height: 1;
}
.header .logo {
  width: 76px;
  height: 26px;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  font-size: 0;
  text-indent: -10px;
  background: url("assets/img/common/logo_header.png") no-repeat;
  background-size: 76px auto;
}
.header .btn-menu-open {
  display: none;
}
.header .gnb {
  padding: 46px 30px 0;
  vertical-align: top;
  text-align: center;
}
.header .gnb > ul {
  display: flex;
  justify-content: center;
}
.header .gnb > ul > li {
  position: relative;
  margin-right: 48px;
}
.header .gnb > ul > li:last-child {
  margin: 0;
}
.header .gnb a {
  display: block;
  line-height: 20px;
  font-size: 16px;
  color: var(--color-font-secondary);
  font-weight: bold;
}

.header .language {
  position: absolute;
  right: 120px;
  top: 40px;
  padding-top: 2px;
}
.header .language .btn-language {
  float: left;
  width: 32px;
  height: 32px;
  overflow: hidden;
  vertical-align: middle;
  font-size: 0;
  text-indent: -10px;
  background: url("assets/img/common/ico_language.png") no-repeat;
  background-size: 32px 32px;
}
.header .language .layer {
  position: absolute;
  left: 50%;
  top: 37px;
  width: 48px;
  height: 120px;
  margin-left: -24px;
  background: url("assets/img/common/bg_language.png") no-repeat;
  background-size: 48px auto;
}
.header .language .layer ul {
  padding-top: 11px;
}
.header .language .layer li {
  margin-top: 8px;
  line-height: 16px;
  text-align: center;
}
.header .language .layer a {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  font-weight: bold;
}
.header .language .layer a.active {
  color: #fff;
}

.header.fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--color-white) !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.header.fixed .logo {
  background-image: url("assets/img/common/logo_header.png") !important;
}
.header.fixed .gnb a {
  color: var(--color-font-secondary) !important;
}
.header.fixed .language .btn-language {
  background-image: url("assets/img/common/ico_language.png") !important;
}

/* footer */
.footer {
  padding: 48px 0 60px 0;
  border-top: 1px solid var(--color-border-layout);
}
.footer::after {
  content: "";
  clear: both;
  display: block;
}
.footer .inner {
  position: relative;
  width: 1264px;
  margin: 0 auto;
  padding-left: 216px;
  box-sizing: border-box;
}
.footer .logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 59px;
  height: 20px;
  display: block;
  overflow: hidden;
  font-size: 0;
  text-indent: -10px;
  background: url("assets/img/common/logo_footer.png") no-repeat;
  background-size: 59px auto;
}
.footer .inquiry {
  font-size: 13px;
  font-weight: 500;
  opacity: 0.9;
  margin-bottom: 32px;
}
.footer .inquiry span {
  color: var(--color-font-primary);
}
.footer .inquiry a {
  color: var(--color-font-secondary);
}
.footer address {
  color: var(--color-font-primary);
  font-style: normal;
  margin-bottom: 12px;
  opacity: 0.9;
}
.footer .copyright {
  color: var(--color-font-secondary);
  font-size: 14px;
  letter-spacing: -0.14px;
}
.footer .copyright img {
  width: 14px;
  vertical-align: middle;
}
.footer .sns {
  position: absolute;
  right: 0;
  top: -10px;
}
.footer .sns li {
  float: left;
  margin-right: 8px;
}
.footer .sns a {
  display: block;
  width: 42px;
  height: 42px;
  overflow: hidden;
  font-size: 0;
  text-indent: -10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 44px 44px;
}
.footer .sns .twitter {
  background-image: url("assets/img/common/ico_twitter.png");
}
.footer .sns .facebook {
  background-image: url("assets/img/common/ico_facebook.png");
}
.footer .sns .instagram {
  background-image: url("assets/img/common/ico_instagram.png");
}

/* contents */
.contents {
  padding: 0 0 200px 0;
}
.wrap-full .contents {
  padding: 0;
}

/* selectbox */
.selectbox {
  height: 52px;
  line-height: 52px;
  padding: 0 36px 0 16px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.16px;
  color: var(--color-white);
  outline: none;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: var(--color-background-input);
  /* background: var(--color-background-input)
    url("assets/img/common/ico_arrow_selectbox.png") no-repeat; */
  background-size: 14px 12px;
  background-position: right 12px center;
}
.select-layer {
  position: relative;
  width: 100%;
  height: 52px;
}
.select-layer .active {
  width: 100%;
  height: 52px;
  line-height: 52px;
  padding: 0 20px 0 16px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.16px;
  color: var(--color-white);
  outline: none;
  border: 0;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid var(--color-background-input);
  /* background: var(--color-background-input)
    url("assets/img/common/ico_arrow_selectbox.png") no-repeat; */
  background-size: 14px 12px;
  background-position: right 12px center;
}
.select-layer .active:hover {
  border-color: var(--color-success);
}
.select-layer .active:focus {
  border-color: var(--color-success);
}
.select-layer .list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  margin-top: 58px;
  display: none;
}
.select-layer .list ul {
  width: 100%;
  padding: 24px 18px 12px 16px;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #474a50;
  background-color: var(--color-background-input);
}
.select-layer .list li {
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
}
.select-layer .list input {
  position: absolute;
  left: -50px;
  top: 0;
  visibility: hidden;
}
.select-layer .list label {
  cursor: pointer;
  font-size: 16px;
  line-height: 1.25;
  color: var(--color-font-gray3);
}
.select-layer .list label:hover {
  color: var(--color-success);
}
.select-layer.open .active {
  border-color: var(--color-success);
  background-color: var(--color-background-input-focus);
  /* background-image: url("assets/img/common/ico_arrow_selectbox_clsoe.png"); */
}
.select-layer.open .list {
  display: block;
}

/* input */
.input-txt {
  height: 50px;
  line-height: 50px;
  text-indent: 12px;
  font-family: Pretendard;
  color: var(--color-input);
  outline: none;
  font-weight: bold;
  font-size: 14px;
  border-radius: 6px;
  background-color: var(--color-background-input);
  border: 1px solid var(--color-border-input);
}
.input-txt + .msg {
  display: none;
  padding-top: 8px;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: -0.12px;
  color: var(--color-success);
}
.input-txt::placeholder {
  color: var(--color-input-placeholder);
  font-weight: normal;
}
.input-txt:hover {
  border-color: var(--color-success);
}
.input-txt:focus {
  background-color: var(--color-background-input-focus);
}
.input-txt:disabled {
  color: var(--color-input-placeholder);
  border-color: var(--color-border-input);
  background-color: var(--color-background-input);
}
.input-txt.error {
  border-color: var(--color-border-input-error);
}
.input-txt.success + .msg {
  display: block;
  color: var(--color-primary);
}
.input-txt.error + .msg {
  display: block;
  color: var(--color-font-red);
  border-color: var(--color-border-input-error);
}

.input-txt.input-txt-dark {
  color: var(--color-input-dark);
  background-color: var(--color-background-input-dark);
  border: 1px solid var(--color-border-input-dark);
}
.input-txt.input-txt-dark:focus {
  border-color: var(--color-success);
  background-color: var(--color-background-input-dark-focus);
}
.input-txt.input-txt-dark:hover {
  border-color: var(--color-success);
}
.input-txt.input-txt-dark::placeholder {
  color: var(--color-input-placeholder-dark);
}
.input-txt.input-txt-dark:disabled {
  color: var(--color-input-placeholder-dark);
  border-color: var(--color-border-input-dark);
  background-color: var(--color-background-input-dark);
}

.input-txtarea {
  padding: 16px;
  box-sizing: border-box;
  color: var(--color-white);
  resize: none;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  border-radius: 6px;
  background-color: var(--color-background-input);
  border: 1px solid var(--color-background-input);
}
.input-txtarea::placeholder {
  color: var(--color-font-gray1);
  font-weight: normal;
}
.input-txtarea:hover {
  border-color: var(--color-success);
}
.input-txtarea:focus {
  background-color: var(--color-background-input-focus);
}

.popup-error {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  z-index: 999;
}
.popup-error:before {
  position: fixed;
  left: 0;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #000;
}
.popup-error .popup-inner {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 464px;
  text-align: center;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  padding: 80px 60px 40px 60px;
  border-radius: 20px;
  background-color: #fe413c;
  z-index: 2;
}
.popup-error p {
  font-family: Pretendard;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  word-break: keep-all;
}
.popup-error .btn-check {
  margin: 40px 0 0 0;
  width: 166px;
  height: 52px;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 800;
  color: #fe413c;
  border-radius: 8px;
  border: 0;
  background: #fff;
}
.popup-error .btn-popup-close {
  position: absolute;
  right: 30px;
  top: 30px;
  border: 0;
  width: 24px;
  height: 24px;
  border: 0;
  /* background: url("assets/img/common/btn_error_close.png") no-repeat; */
  background-size: 24px auto;
}
.popup-error .btn-popup-close span {
  display: none;
}

/*-----------------------------------------
            mobile
-------------------------------------------*/

@media all and (max-width: 1350px) {
  .header h1 {
    left: 0px;
  }
  .header .util {
    margin-right: 0px;
  }
  .header .language {
    right: 0;
  }
}

@media all and (max-width: 1023px) {
  /* header */
  .header {
    border-bottom: 0;
  }
  .header .inner {
    min-width: 100%;
    height: 60px;
  }
  .header h1 {
    left: 20px;
    top: 21px;
  }
  .header .logo {
    width: 53px;
    height: 18px;
    background: url("assets/img/common/logo_header_m.png") no-repeat;
    background-size: 53px auto;
  }
  .header .menu {
    display: none;
  }
  .header .btn-menu-open {
    position: absolute;
    right: 16px;
    top: 14px;
    display: block;
    width: 32px;
    height: 32px;
    border: 0;
    background: url("assets/img/common/btn_menu_open.png") no-repeat;
    background-size: 32px 32px;
  }
  .header .btn-menu-open span {
    display: none;
  }

  .header.layer-open .btn-menu-open {
    right: 15px;
    background-image: url("assets/img/common/btn_menu_close.png") !important;
    z-index: 2;
  }
  .header.layer-open h1 {
    display: none;
  }
  .header.layer-open .menu {
    height: 100vh;
    display: block;
    background: var(--color-white);
    float: none;
    margin-top: 0;
    overflow-y: scroll;
  }
  .header .gnb {
    padding-top: 92px;
    text-align: left;
    display: block;
  }
  .header .gnb li {
    width: 100%;
    margin-bottom: 26px;
  }
  .header .gnb li a {
    display: inline-block;
    line-height: 32px;
    font-size: 24px;
    font-weight: 800;
    color: var(--color-font-secondary) !important;
  }
  .header .gnb > ul {
    display: block;
    justify-content: baseline;
  }

  .header .util {
    margin: 0;
    display: block;
    padding: 40px 0 32px 0;
    margin: 0 20px;
    text-align: left;
  }

  .header .language {
    position: fixed;
    left: 16px;
    right: initial;
    bottom: 32px;
    top: initial;
    clear: both;
    padding: 0;
  }
  .header .language .layer-language {
    position: relative;
    left: inherit;
    top: initial;
    width: auto;
    height: auto;
    margin: 0;
    display: inline-block !important;
    background: none;
  }
  .header .language .layer-language ul {
    float: left;
    padding: 0;
  }
  .header .language .layer-language li {
    float: left;
    position: relative;
    margin-top: 0;
    line-height: 32px;
  }
  .header .language .layer-language li a {
    color: #b1b1b1;
    font-size: 16px;
  }
  .header .language .layer-language li {
    padding-right: 16px;
    margin-right: 12px;
  }
  .header .language .layer-language li:before {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -2px;
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background: #b1b1b1;
  }
  .header .language .layer-language li.pt {
    padding-right: 0;
    margin-right: 0;
  }
  .header .language .layer-language li.pt:before {
    display: none;
  }
  .header .language .layer-language a.active {
    color: var(--color-font-secondary);
  }

  .header.fixed .btn-menu-open {
    background-image: url("assets/img/common/btn_menu_open.png") !important;
  }
  .header.fixed.layer-open .btn-menu-open {
    background-image: url("assets/img/common/btn_menu_close.png") !important;
  }

  body {
    min-width: 360px;
  }
  body .wrap {
    min-width: 360px;
  }

  .contents {
    padding: 0 0 100px 0;
    min-width: 100%;
  }

  /* footer */
  .footer {
    padding: 32px 0 40px 0;
  }
  .footer .inner {
    width: 100%;
    padding: 0 20px;
  }
  .footer .logo {
    position: relative;
    left: initial;
    top: initial;
    width: 48px;
    height: 16px;
    margin-bottom: 40px;
    background: url("assets/img/common/logo_footer.png") no-repeat;
    background-size: 48px auto;
  }
  .footer .inquiry {
    font-size: 13px;
    opacity: 0.9;
    margin-bottom: 32px;
  }
  .footer address {
    font-size: 13px;
    line-height: 1.54;
    margin-bottom: 12px;
  }
  .footer .copyright {
    font-size: 13px;
    letter-spacing: -0.13px;
  }
  .footer .copyright img {
    width: 14px;
    vertical-align: middle;
    margin-top: -1px;
  }
  .footer .sns {
    right: 7px;
    top: -16px;
  }
  .footer .sns li {
    margin-right: 4px;
  }
  .footer .sns a {
    width: 48px;
    height: 48px;
    background-size: 48px 48px;
  }
  .footer .sns .twitter {
    background-image: url("assets/img/common/ico_twitter_m.png");
  }
  .footer .sns .facebook {
    background-image: url("assets/img/common/ico_facebook_m.png");
  }
  .footer .sns .instagram {
    background-image: url("assets/img/common/ico_instagram_m.png");
  }
}
